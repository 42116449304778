import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import { withTranslation } from 'react-i18next';
import {
    Card, CardBody, CardText, CardHeader,
    CardTitle, CustomInput, Col, Row,
    FormGroup, Form, Label, Input,
    Button, Badge, InputGroup, FormText
} from 'reactstrap';
import { FaTools, FaTrashAlt } from 'react-icons/fa';
import { AsyncPaginate } from 'react-select-async-paginate';
import rowJobPriorityService from '../rowjobpriorities/RowJobPriorityService';
import rowPersonService from '../rowpeople/RowPersonService';
import rowStatusService from '../rowstatuses/RowStatusService';
import userPreferenceService from '../userpreferences/UserPreferenceService';
import parishService from '../parishes/ParishService';
import communityService from '../communities/CommunityService';
import countyService from '../counties/CountyService';
import rowGrantService from '../rowgrants/RowGrantService';
const filterdefinitions = {
    "rowjobfilters": [
        {
            label: "",
            name: "",
            type: "",
            conditions: [], // gt,lt,eq,contains
        },
        {
            label: "County",
            name: "county",
            type: "county",
            conditions: ["eq"]
        },
        {
            label: "Parish",
            name: "parish",
            type: "parish",
            conditions: ["eq"]
        },
        {
            label: "Community",
            name: "community",
            type: "community",
            conditions: ["eq"]
        },
        {
            label: "Grant",
            name: "grant",
            type: "grant",
            conditions: ["eq"]
        },
        {
            label: "Due Date",
            name: "duedate",
            type: "date",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "Priority",
            name: "priority",
            type: "priority",
            conditions: ["eq"]
        },
        {
            label: "Job Number",
            name: "jobnumber",
            type: "int",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "Description",
            name: "description",
            type: "string",
            conditions: ["contains"]
        },
        {
            label: "Status",
            name: "status",
            type: "status",
            conditions: ["eq"]
        },
        {
            label: "Completion Date",
            name: "completiondate",
            type: "date",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "ROW",
            name: "prow",
            type: "string",
            conditions: ["eq"]
        },
        {
            label: "Wardens Comments",
            name: "wardenscomments",
            type: "string",
            conditions: ["contains"]
        },
        {
            label: "Reported By",
            name: "reportedby",
            type: "person",
            conditions: ["eq"]
        },
        {
            label: "Reported Date",
            name: "reporteddate",
            type: "date",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "Assigned To",
            name: "assignedto",
            type: "warden",
            conditions: ["eq"]
        },
    ],
    "bridgefilters": [
        {
            label: "",
            name: "",
            type: "",
            conditions: [], // gt,lt,eq,contains
        },
        {
            label: "Warden",
            name: "warden",
            type: "warden",
            conditions: ["eq"]
        },
        {
            label: "Name",
            name: "name",
            type: "string",
            conditions: ["eq", "contains"]
        },
        {
            label: "Code",
            name: "code",
            type: "string",
            conditions: ["eq", "contains"]
        },
        {
            label: "Watercourse",
            name: "watercourse",
            type: "string",
            conditions: ["eq", "contains"]
        },
        {
            label: "Build Date",
            name: "builddate",
            type: "date",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "Last Inspected",
            name: "lastinspected",
            type: "date",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "County",
            name: "county",
            type: "county",
            conditions: ["eq"]
        },
        {
            label: "Parish",
            name: "parish",
            type: "parish",
            conditions: ["eq"]
        },
        {
            label: "Community",
            name: "community",
            type: "community",
            conditions: ["eq"]
        },
        {
            label: "Queries",
            name: "queries",
            type: "string",
            conditions: ["eq", "contains"]
        },

    ],
    "bridgeinspectionfilters": [
        {
            label: "",
            name: "",
            type: "",
            conditions: [], // gt,lt,eq,contains
        },
        {
            label: "Name",
            name: "name",
            type: "string",
            conditions: ["eq", "contains"]
        },
        {
            label: "Code",
            name: "code",
            type: "string",
            conditions: ["eq", "contains"]
        },
        {
            label: "Inspection Date",
            name: "inspectiondate",
            type: "date",
            conditions: ["lt", "gt", "eq"]
        },
        {
            label: "Warden",
            name: "warden",
            type: "warden",
            conditions: ["eq"]
        },
        {
            label: "Watercourse",
            name: "watercourse",
            type: "string",
            conditions: ["eq", "contains"]
        },
        {
            label: "County",
            name: "county",
            type: "county",
            conditions: ["eq"]
        },
        {
            label: "Parish",
            name: "parish",
            type: "parish",
            conditions: ["eq"]
        },
        {
            label: "Community",
            name: "community",
            type: "community",
            conditions: ["eq"]
        },
    ]
};

class FilterManager extends Component {
    constructor(props) {
        super(props);
        if (props.type) {

        }
        else {

        }
        this.state = {
            type: props.type,
            fieldtypes: filterdefinitions[props.type].sort(),
            filter: [],
            savedfilters: [],
            filtername: ""
        };

    }
    componentDidMount() {
        this.getFilters(this.props.type);
    }

    async getFilters(name) {
        try {
            const data = await userPreferenceService.getbyname(name);
            //console.log(data);
            if (data.payload !== "") {
                let result = JSON.parse(data.payload);
                this.setState({ filter: this.props.override ? this.props.override : result.filter, savedfilters: result.savedfilters });
                if (this.props.onChange) {
                    this.props.onChange(this.props.override ? this.props.override : result.filter);
                }
            }
            else {
                if (this.props.override) {
                    this.setState({ filter: this.props.override })
                    if (this.props.onChange) {
                        this.props.onChange(this.props.override);
                    }
                }

            }
            //this.props.onChangePath(data.result);
        }
        catch (error) {
            console.log(error);
            // Handle error
        }
    }
    saveFilters = (filters, savedfilters) => {
        const { history } = this.props;
        let postvalues = { name: this.props.type, payload: JSON.stringify({ filter: filters ? filters : this.state.filter, savedfilters: savedfilters ? savedfilters : this.state.savedfilters }) };

        (async () => {
            await userPreferenceService.add(postvalues);
            //history.push('/rowjobs');
            //history.goBack();
        })();
    }
    addCondition = (e) => {
        this.state.filter.push({ id: this.uuidv4(), name: '', condition: "eq", value: '' });
        this.setState({ filter: this.state.filter })
        this.saveFilters();
    }
    filterGroupChange = (filters) => {
        this.setState({ filter: filters });
        this.saveFilters(filters, this.state.savedfilters);
        if (this.props.onChange) {
            this.props.onChange(filters);
        }
    }
    changeFilter = (e) => {
        this.setState({ filtername: e.target.name, filter: this.state.savedfilters.find(o => o.name == e.target.name).filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.savedfilters.find(o => o.name == e.target.name).filter);
        }
    }
    saveFilterAs = (name) => {
        if (name !== null && name !== "") {
            let savedfilters = [];
            let existing = false;
            for (var f of this.state.savedfilters) {
                if (f.name == name) {
                    savedfilters.push({ name: name, filter: this.state.filter });
                    existing = true;
                }
                else {
                    savedfilters.push(f);
                }
            }
            if (!existing) {
                savedfilters.push({ name: name, filter: this.state.filter });
            }
            this.setState({ savedfilters: savedfilters });
            this.saveFilters(this.state.filter, savedfilters);
        }
    }
    deleteFilter = (name) => {
        if (name !== null && name !== "") {
            let savedfilters = [];
            for (var f of this.state.savedfilters) {
                if (f.name == name) {
                    // skip
                }
                else {
                    savedfilters.push(f);
                }
            }
            this.setState({ savedfilters: savedfilters });
            this.saveFilters(this.state.filter, savedfilters);
        }
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    render() {
        /*const validfieldtypes = [];
        for (var o of this.state.fieldtypes) {
            if (!this.state.conditions.find(c => c.name == o.name)) {
                validfieldtypes.push(o);
                console.log(o);
            }
        }*/
        return (
            <div>
                {this.state.savedfilters ? this.state.savedfilters.map(f =>
                    <span><Button size="sm" onClick={this.changeFilter} name={f.name}>{f.name} </Button>&nbsp;</span>
                ) : ""}
                {this.state.savedfilters && this.state.savedfilters.length > 0 ? (<div>&nbsp;</div>) : ''}
                <FilterGroup name={this.state.filtername} value={this.state.filter} types={this.state.fieldtypes} onChange={this.filterGroupChange} onSave={this.saveFilterAs} onDelete={this.deleteFilter} />
            </div>)

    }
}
class FilterGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: this.props.value,
            filtername: this.props.name ? this.props.name : ""
        };
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.value !== this.state.filters) {
            this.setState({ filters: nextProps.value });
        }
        if (nextProps.name !== this.state.filtername) {
            this.setState({ filtername: nextProps.name });
        }
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    addFilter = (e) => {
        this.state.filters.push({ id: this.uuidv4(), name: '', condition: "eq", value: '' });
        this.setState({ filters: this.state.filters })
        console.log("add");
    }
    filterChanged = (filter) => {
        const newfilters = [];
        for (var f of this.state.filters) {
            if (f.id == filter.id) {
                newfilters.push(filter);
            }
            else {
                newfilters.push(f);
            }
        }
        this.setState({ filters: newfilters });
        if (this.props.onChange) {
            this.props.onChange(newfilters)
        }
    }
    filterRemove = (filter) => {
        const newfilters = [];
        for (var f of this.state.filters) {
            if (f.id == filter.id) {
                // skip
            }
            else {
                newfilters.push(f);
            }
        }
        this.setState({ filters: newfilters });
        if (this.props.onRemove) {
            this.props.onRemove(newfilters)
        }
        if (this.props.onChange) {
            this.props.onChange(newfilters)
        }
    }
    filternameChange = (e) => {
        this.setState({ filtername: e.target.value });
    }
    saveFilter = () => {
        if (this.state.filtername != "") {
            if (this.props.onSave) {
                this.props.onSave(this.state.filtername);
            }
        }
    }
    deleteFilter = () => {
        if (this.state.filtername != "") {
            if (this.props.onDelete) {
                this.props.onDelete(this.state.filtername);
            }
        }
    }
    render() {
        return (
            <div>

                {this.state.filters ? this.state.filters.map(f =>
                    <FilterItem key={f.id} value={f} types={this.props.types} onChange={this.filterChanged} onRemove={this.filterRemove.bind()} />
                ) : ""}
                <Button size="sm" onClick={this.addFilter}>Add condition</Button>
                {this.state.filters && this.state.filters.length > 0 ? (
                    <Row>
                        <Col>
                            Save filter as
                        </Col>
                        <Col>
                            <Input type="text" value={this.state.filtername} name="filtername" onChange={this.filternameChange} />
                        </Col>
                        <Col>
                        </Col>
                        <Col>
                            <Button size="sm" onClick={this.saveFilter}>Save</Button>&nbsp;
                            {this.state.filtername !== "" ? (
                                <Button size="sm" onClick={this.deleteFilter}><FaTrashAlt /></Button>
                            ) : ''}
                        </Col>
                    </Row>
                ) : ""}
            </div>
        )
    }
}
class FilterItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: props.value,
            priorityLevel: null,
            person: null,
            warden: null,
            status: null,
            parish: null,
            community: null,
            county: null,
            grant: null
        };
    }
    componentDidMount() {
        this.loadValueDetail();
    }
    loadValueDetail() {
        console.log("loadValueDetail");
        const fieldtype = this.state.filter.name === '' ? null : this.props.types.find(o => o.name === this.state.filter.name);
        if (this.state.filter && this.state.filter.value) {
            let responseJSON = {};
            switch (fieldtype.type) {
                case "person":
                    (async () => {
                        try {
                            const data = await rowPersonService.get(this.state.filter.value);
                            this.setState({ person: data });
                        } catch (ex) { }
                    })()
                    break;
                case "warden":
                    (async () => {
                        try {
                            const data = await rowPersonService.get(this.state.filter.value);
                            this.setState({ warden: data });
                        } catch (ex) { }
                    })()
                    break;
                case "status":
                    (async () => {
                        try {
                            const data = await rowStatusService.get(this.state.filter.value);
                            this.setState({ status: data });
                        } catch (ex) { }
                    })()
                    break;
                case "priority":
                    (async () => {
                        try {
                            const data = await rowJobPriorityService.get(this.state.filter.value);
                            this.setState({ priorityLevel: data });
                        } catch (ex) { }
                    })()
                    break;
                case "county":
                    (async () => {
                        try {
                            const data = await countyService.get(this.state.filter.value);
                            this.setState({ county: data });
                        } catch (ex) { }
                    })()
                    break;
                case "community":
                    (async () => {
                        try {
                            const data = await communityService.get(this.state.filter.value);
                            this.setState({ community: data });
                        } catch (ex) { }
                    })()
                    break;
                case "parish":
                    (async () => {
                        try {
                            const data = await parishService.get(this.state.filter.value);
                            this.setState({ parish: data });
                        } catch (ex) { }
                    })()
                    break;
                case "grant":
                    (async () => {
                        try {
                            const data = await rowGrantService.get(this.state.filter.value);
                            this.setState({ grant: data });
                        } catch (ex) { }
                    })()
                    break;
                default:
                //
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.value !== this.state.filter) {
            this.setState({ filter: nextProps.value });
        }
        this.loadValueDetail();
    }
    nameChange = (e) => {
        this.state.filter.name = e.target.value;
        this.setState({ filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    conditionChange = (e) => {
        this.state.filter.condition = e.target.value;
        this.setState({ filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    valueChange = (e) => {
        this.state.filter.value = e.target.value;
        this.setState({ filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handlePriorityLevelSelectChange(e) {
        this.state.priorityLevel = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ priorityLevel: this.state.priorityLevel, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handlePersonSelectChange(e) {
        this.state.person = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ person: this.state.person, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handleWardenSelectChange(e) {
        this.state.warden = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ warden: this.state.warden, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handleStatusSelectChange(e) {
        this.state.status = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ status: this.state.status, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handleParishSelectChange(e) {
        this.state.parish = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ parish: this.state.parish, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handleCountySelectChange(e) {
        this.state.county = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ county: this.state.county, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handleCommunitySelectChange(e) {
        this.state.community = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ community: this.state.community, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    handleGrantSelectChange(e) {
        this.state.grant = e.obj;
        this.state.filter.value = e.obj.id;
        this.setState({ grant: this.state.grant, filter: this.state.filter });
        if (this.props.onChange) {
            this.props.onChange(this.state.filter);
        }
    }
    removeFilter = () => {
        if (this.props.onRemove) {
            this.props.onRemove(this.state.filter);
        }
    }
    render() {

        const fieldtype = this.state.filter.name === '' ? null : this.props.types.find(o => o.name === this.state.filter.name);

        return (
            <Row>
                <Col>
                    <Input value={this.state.filter.name} type="select" name="name" placeholder="filter type" onChange={this.nameChange}>
                        {this.props.types.map(fieldtype =>
                            <option value={fieldtype.name} >{fieldtype.label}</option>
                        )}
                    </Input>
                </Col>
                <Col>
                    {fieldtype ? (
                        <Input value={this.state.filter.condition} type="select" name="condition" onChange={this.conditionChange}>
                            {fieldtype.conditions.find(o => o == "eq") ? (
                                <option value="eq">Equals</option>
                            ) : ''}
                            {fieldtype.conditions.find(o => o == "lt") ? (
                                <option value="lt">Less than</option>
                            ) : ''}
                            {fieldtype.conditions.find(o => o == "gt") ? (
                                <option value="gt">Greater than</option>
                            ) : ''}
                            {fieldtype.conditions.find(o => o == "contains") ? (
                                <option value="contains">Contains</option>
                            ) : ''}

                        </Input>
                    ) : 'Select a field'}
                </Col>
                <Col>
                    {fieldtype ?
                        fieldtype.type === "date" ? (
                            <Input type="date" name="value" value={this.state.filter.value} onChange={this.valueChange} />
                        ) : fieldtype.type === "string" ? (
                            <Input type="text" name="value" value={this.state.filter.value} onChange={this.valueChange} />
                        ) : fieldtype.type === "int" ? (
                            <Input type="number" name="value" value={this.state.filter.value} onChange={this.valueChange} />
                        ) : fieldtype.type === "county" ? (
                            <AsyncPaginate
                                value={this.state.county ? { label: this.state.county.name, value: this.state.county.id } : {}}
                                loadOptions={
                                    async (search, loadedCountyOptions, { page }) => {
                                        const responseJSON = await countyService.selectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleCountySelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />
                        ) : fieldtype.type === "parish" ? (
                            <AsyncPaginate
                                value={this.state.parish ? { label: this.state.parish.name, value: this.state.parish.id } : {}}
                                loadOptions={
                                    async (search, loadedParishOptions, { page }) => {
                                        const responseJSON = await parishService.selectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleParishSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />
                        ) : fieldtype.type === "community" ? (
                            <AsyncPaginate
                                value={this.state.community ? { label: this.state.community.name, value: this.state.community.id } : {}}
                                loadOptions={
                                    async (search, loadedCommunityOptions, { page }) => {
                                        const responseJSON = await communityService.selectlist(search, page, 0);
                                        return {

                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleCommunitySelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />
                        ) : fieldtype.type === "priority" ? (
                            <AsyncPaginate
                                value={this.state.priorityLevel ? { label: this.state.priorityLevel.prowPriorityId, value: this.state.priorityLevel.id } : {}}
                                loadOptions={
                                    async (search, loadedJobPriorityOptions, { page }) => {
                                        const responseJSON = await rowJobPriorityService.selectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handlePriorityLevelSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />

                        ) : fieldtype.type === "person" ? (

                            <AsyncPaginate
                                value={this.state.person ? { label: this.state.person.firstname + ' ' + this.state.person.surname, value: this.state.person.id } : {}}
                                loadOptions={
                                    async (search, loadedReportedByOptions, { page }) => {
                                        const responseJSON = await rowPersonService.selectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handlePersonSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />

                        ) : fieldtype.type === "warden" ? (
                            <AsyncPaginate
                                value={this.state.warden ? { label: this.state.warden.firstname + ' ' + this.state.warden.surname, value: this.state.warden.id } : {}}
                                loadOptions={
                                    async (search, loadedAssignedToOptions, { page }) => {
                                        const responseJSON = await rowPersonService.wardenselectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleWardenSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />


                        ) : fieldtype.type === "status" ? (


                            <AsyncPaginate
                                value={this.state.status ? { label: this.state.status.name, value: this.state.status.id } : {}}
                                loadOptions={
                                    async (search, loadedStatusOptions, { page }) => {
                                        const responseJSON = await rowStatusService.selectlist(search, page, 0);


                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleStatusSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />
                        ) : fieldtype.type === "grant" ? (

                            <AsyncPaginate
                                value={this.state.grant ? { label: this.state.grant.name, value: this.state.grant.id } : {}}
                                loadOptions={
                                    async (search, loadedGrantOptions, { page }) => {
                                        const responseJSON = await rowGrantService.selectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleGrantSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                            />

                        )
                            : '' : ''}


                </Col>
                <Col><Button size="sm" onClick={this.removeFilter}>Remove</Button></Col>
            </Row>
        )

    }
}
export default FilterManager;