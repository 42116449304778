import React, { Component } from 'react';
import { withRouter } from 'react-router';
import rowGrantService from './RowGrantService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Card, CardBody, CardText, CardHeader, CardTitle, CustomInput, Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { SelectFetch } from 'react-select-fetch';
import { AsyncPaginate } from 'react-select-async-paginate';

import authService from '../api-authorization/AuthorizeService';

import { wgs84, osgb } from "../osgb";



class RowGrantEditPlain extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            entity: null,
            loading: true,
            locked: false,
            isnew: false,
        };

        const { match } = this.props;
        this.id = match.params.id;
    }




    async getSelectOptions() {

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();

    }
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        this.setState({ ready: true, isAuthenticated: authenticated, user, role: user && user.role, isAdmin: user.role.includes("Administrator") });
        this.userStateReturned();
    }
    userStateReturned() {
        this.retrieveFormData();
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;

        this.setState({ entity: this.state.entity });
    }

    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = this.state.entity;
        
        if (this.state.isnew) {
            (async () => {
                await rowGrantService.add(postvalues);
                //history.push('/rowmaterials');
                history.goBack();
            })();
        } else {
            (async () => {
                await rowGrantService.update(this.id, postvalues);
                //history.push('/rowmaterials');
                history.goBack();
            })();
        }

    }
    
    
    renderForm(entity) {
        const { t, i18n } = this.props;
        return (

            <Form onSubmit={this.handleValidSubmit}>
                <Card>
                    <CardBody>
                        <FormGroup row>
                            <Label for="name" sm={2}>Name</Label>
                            <Col sm={10}>
                                <Input type="text" name="name" value={entity.name} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="cost" sm={2}>Description</Label>
                            <Col sm={10}>
                                <Input type="text" name="description" value={entity.description} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
               
                <div>&nbsp;</div>
                <FormGroup>
                    <Button onClick={this.handleValidSubmit}>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </FormGroup>
            </Form>

        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm(this.state.entity);

        return (
            <div>
                <h1 id="tabelLabel">Grant</h1>
                {contents}
            </div>
        );
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    async retrieveFormData() {
        let isnew = false;
        let data = {}
        let duplicates = [];
        if (this.id) {
            data = await rowGrantService.get(this.id);
        }
        else {
            data = {
                id: this.uuidv4(),
                name: '',
                description: ''
            }
            isnew = true;
        }

        this.setState({ entity: data, loading: false, isnew: isnew });
    }
}

export const RowGrantEdit = withTranslation()(withRouter(RowGrantEditPlain));