import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants'

export class UserPreferenceService  {
	async getData(page, pageSize, sortOrder, searchString) {
		const token = await authService.getAccessToken();
		const offset = (page - 1) * pageSize;

		// Taken from here: https://fetch.spec.whatwg.org/#fetch-api
		let url = "userpreference?" + "offset=" + offset + "&limit=" + pageSize + "&sortOrder=" + sortOrder;
		if (searchString !== "")
			url += "&searchString=" + encodeURIComponent(searchString);

		try {
			const response = await fetch(url, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}

				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async delete(entityId) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('userpreference/' +entityId, {
				method: 'DELETE',
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async get(entityId) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('userpreference/' + entityId, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	async getbyname(name) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('userpreference/byname?name=' + name, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	async add(entity) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('userpreference', {
				method: 'POST',
				body: JSON.stringify(entity),
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}

	}

	//async update(id, entity) {
	//	const token = await authService.getAccessToken();

	//	try {
	//		const headers = { 'content-type': 'application/json' };
	//		if (token) headers['Authorization'] = `Bearer ${token}`;

	//		const response = await fetch('userpreference/' + id, {
	//			method: 'PUT',
	//			body: JSON.stringify(entity),
	//			headers: headers,
	//		});

	//		if (response.ok) {
	//			return;
	//		}
	//		else {
	//			throw new Error("HTTP error! Code: " + response.status);
	//		}
	//	}
	//	catch (error) {
	//		console.log(error);
	//		throw error;
	//	}
	//}
	//

	static get instance() { return userPreferenceService; } // TODO: Check singleton/lifetime
}

const userPreferenceService = new UserPreferenceService();

export default userPreferenceService;