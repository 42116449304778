import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { UserRouter } from './components/users/UserRouter';
import { RowJobRouter } from './components/rowjobs/RowJobRouter';
import { BridgeRouter } from './components/bridges/BridgeRouter';
import { BridgeInspectionRouter } from './components/bridgeinspections/BridgeInspectionRouter';
import { RowMaterialRouter } from './components/rowmaterials/RowMaterialRouter';
import { RowPersonRouter } from './components/rowpeople/RowPersonRouter';
import { RowGrantRouter } from './components/rowgrants/RowGrantRouter';
import { RowWardenAreaRouter } from './components/rowwardenareas/RowWardenAreaRouter';


//import PathMap from './components/pathmap/PathMap'
import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <AuthorizeRoute path='/fetch-data' component={FetchData} />
                <AuthorizeRoute path='/users' component={UserRouter} />
                <AuthorizeRoute path='/rowjobs' component={RowJobRouter} />
                <AuthorizeRoute path='/bridges' component={BridgeRouter} />
                <AuthorizeRoute path='/bridgeinspections' component={BridgeInspectionRouter} />
                <AuthorizeRoute path='/rowmaterials' component={RowMaterialRouter} />
                <AuthorizeRoute path='/rowpeople' component={RowPersonRouter} />
                <AuthorizeRoute path='/rowgrants' component={RowGrantRouter} />
                <AuthorizeRoute path='/rowwardenareas' component={RowWardenAreaRouter} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
