import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bridgeService from './BridgeService';
import { Pager } from '../Pager';
import { Badge, FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';
import FilterManager from '../filtermanager/FilterManager';
import BridgePopup from './BridgePopup';
import BridgeMap from '../pathmap/BridgeMap';
export class BridgeList extends Component {
    constructor(props) {
        super(props);
        const { match } = this.props;
        this.duebywardenid = match.params.duebywardenid;
        let filteroverride = null;
        if (this.duebywardenid) {
            let now = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            
            filteroverride=[
                {
                    condition: "lt",
                    id: "9a250652-1381-4ab4-b19b-6b5f40a602bd",
                    name: "lastinspected",
                    value: now.getFullYear() + "-" + String(now.getMonth()+1).padStart(2, '0') + "-" + String(now.getDate()).padStart(2,'0')
                },
                {
                    condition: "eq",
                    id: "141132c0-0d6a-4f90-aa71-dbad4db614e3",
                    name: "warden",
                    value: this.duebywardenid
                }
            ]
        }
        this.state = {
            entities: [],
            page: 1,
            pageSize: 20,
            maxPages: 15,
            sortOrder: "lastinspected_desc",
            searchString: "",
            loading: true,
            filters: filteroverride ? filteroverride : [],
            selectedBridge: null,
            filteroverride: filteroverride,
            showmap: false
        };
    }

    componentDidMount() {
        this.populateData();
    }

    handlePageChange = (page) => {
        this.setState({ page: page }, () => this.populateData());
    }

    handleHeaderClick = (event, header) => {
        event.preventDefault();

        let newSortOrder = this.state.sortOrder;

        switch (header) {
            case 'code': {
                newSortOrder = this.state.sortOrder === 'code' ? 'code_desc' : 'code';
                break;
            }
            case 'name': {
                newSortOrder = this.state.sortOrder === 'name' ? 'name_desc' : 'name';
                break;
            }
            case 'community': {
                newSortOrder = this.state.sortOrder === 'community' ? 'community_desc' : 'community';
                break;
            }
            case 'parish': {
                newSortOrder = this.state.sortOrder === 'parish' ? 'parish_desc' : 'parish';
                break;
            }
            case 'lastinspected': {
                newSortOrder = this.state.sortOrder === 'lastinspected' ? 'lastinspected_desc' : 'lastinspected';
                break;
            }
            case 'builddate': {
                newSortOrder = this.state.sortOrder === 'builddate' ? 'builddate_desc' : 'builddate';
                break;
            }
            case 'watercourse': {
                newSortOrder = this.state.sortOrder === 'watercourse' ? 'watercourse_desc' : 'watercourse';
                break;
            }
            case 'warden': {
                newSortOrder = this.state.sortOrder === 'warden' ? 'warden_desc' : 'warden';
                break;
            }
        }

        this.setState({ page: 1, sortOrder: newSortOrder }, () => this.populateData());
        return false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleSearchFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ page: 1 }, () => this.populateData());
        //this.populateData();
    }

    handleSearchFormReset = (event) => {
        event.preventDefault();
        this.setState({ page: 1, searchString: "" }, () => this.populateData());
    }
    selectBridge(o) {
        this.setState({ selectedBridge: o });
    }
    onPopupClose = () => {
        this.setState({ selectedBridge: null });
        this.populateData();
    }
    showmap = () => {
        this.setState({ showmap: true });
    }
    mapclosed = () => {
        this.setState({ showmap: false });
    }
    mapSelectBridge = (bridge) => {
        console.log("mapSelectBridge");
        this.selectBridge(bridge);
    }
    renderTable() {
        const { entities, total, sortOrder } = this.state;

        return (
            <div>
                <BridgeMap
                    filters={this.state.filters}
                    visible={this.state.showmap}
                    sortOrder={this.state.sortOrder}
                    searchString={this.state.searchString}
                    onClose={this.mapclosed}
                    onSelectBridge={this.mapSelectBridge }
                />
                <table className='table table-striped' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'code')} >
                                    Code&nbsp;
                                    {sortOrder == 'code' && <FaSortAlphaDown />}
                                    {sortOrder == 'code_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'name')} >
                                    Name&nbsp;
                                    {sortOrder == 'name' && <FaSortAlphaDown />}
                                    {sortOrder == 'name_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'watercourse')} >
                                    Watercourse&nbsp;
                                    {sortOrder == 'watercourse' && <FaSortAlphaDown />}
                                    {sortOrder == 'watercourse_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'warden')} >
                                    Warden&nbsp;
                                    {sortOrder == 'warden' && <FaSortAlphaDown />}
                                    {sortOrder == 'warden_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'parish')} >
                                    Parish&nbsp;
                                    {sortOrder == 'parish' && <FaSortAlphaDown />}
                                    {sortOrder == 'parish_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'lastinspected')} >
                                    Last Insp&nbsp;
                                    {sortOrder == 'lastinspected' && <FaSortAlphaDown />}
                                    {sortOrder == 'lastinspected_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            {/*<th>*/}
                            {/*    <a href="#" onClick={(e) => this.handleHeaderClick(e, 'builddate')} >*/}
                            {/*        Build Date&nbsp;*/}
                            {/*        {sortOrder == 'builddate' && <FaSortAlphaDown />}*/}
                            {/*        {sortOrder == 'buildddate_desc' && <FaSortAlphaUp />}*/}
                            {/*    </a>*/}
                            {/*</th>*/}
                            <th />


                        </tr>

                    </thead>
                    <tbody>
                        {entities.map(entity => {
                            return [
                                (<tr style={{ cursor: 'pointer' }} onClick={() => this.selectBridge(entity)} key={entity.id} >
                                    <td>{entity.code}</td>
                                    <td>{entity.name}</td>
                                    <td>{entity.watercourse}</td>
                                    <td>{entity.warden ? entity.warden.firstname + ' ' + entity.warden.surname : ''}</td>
                                    <td>{entity.parish ? entity.parish.name : ''}</td>
                                    {/*<td>{entity.community ? entity.community.name.replace(" Community", "") : ''}</td>*/}
                                    <td>{this.getLastInspection(entity) ? this.getLastInspection(entity).toLocaleDateString() : ""}</td>
                                    <td>
                                        <Link onClick={() => this.selectBridge(entity)}>
                                            <FaEdit />
                                        </Link>
                                    </td>
                                </tr>), (
                                    entity.queries !== "" ?
                                        <tr>
                                            <td colspan="9">{entity.queries}</td>
                                        </tr>
                                        : ''
                                )
                            ]
                        }



                        )}
                    </tbody>
                </table>
                <Pager totalItems={total} page={this.state.page} pageSize={this.state.pageSize} maxPages={this.state.maxPages} handlePageChange={this.handlePageChange} />
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])).toLocaleDateString() : "";
    }
    toDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])) : null;
    }
    getLastInspection(e) {
        var lastinspection = this.toDate(e.lastInspected);
        for (var inspection of e.inspections) {
            let d = this.toDate(inspection.inspectionDate);
            if (lastinspection === null || d > lastinspection) {
                lastinspection = d;
            }
        }
        return lastinspection;
    }
    onAddInspection = (o) => {
        const { history } = this.props;

        history.push('/bridgeinspections/add/' + o.id);
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTable();

        return (
            <div>
                <h1 id="tableLabel">Bridges</h1>
                <FilterManager onChange={this.filterChange} type={"bridgefilters"} override={this.state.filteroverride} />
                <BridgePopup modal={false} value={this.state.selectedBridge} onAddInspection={this.onAddInspection} onClose={this.onPopupClose }/>
                <div>&nbsp;</div>
                <Form inline onSubmit={this.handleSearchFormSubmit}>
                    <FormGroup>
                        <Input type="text" name="searchString" value={this.state.searchString} onChange={this.handleInputChange} placeholder="search string" />
                    </FormGroup>&nbsp;
                    <Button size="sm">Search</Button>&nbsp;
                    <Button size="sm" onClick={this.handleSearchFormReset}>Reset</Button>&nbsp;
                    <Button size="sm" onClick={this.showmap}>Map</Button>&nbsp;
                </Form><br />
                {contents}
            </div>
        );
    }
    filterChange = (filters) => {
        this.setState({ filters: filters });
        this.setState({ page: 1 }, () => this.populateData());
    }
    async populateData() {
        try {

            const data = await bridgeService.getData(
                this.state.page,
                this.state.pageSize,
                this.state.sortOrder,
                this.state.searchString,
                this.state.filters
            );

            //data.entities.forEach(function (item) {
            //    var location = item.location;
            //    //item.location = location.coordinates;
            //    var leader = item.leader;
            //    if (leader) {
            //        item.leader = leader.firstname + " " + leader.surname;
            //        item.leaderid = leader.id;
            //    } else {
            //        item.leader = "";
            //    }

            //});
            this.setState({ total: data.total, entities: data.entities, loading: false });
        }
        catch (error) {
            // Handle error
        }
    }
}