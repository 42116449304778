import React, { Component } from 'react';
import { withRouter } from 'react-router';
import userService from './UserService';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { Row,Col, FormGroup, Form, Label, Input, Button } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import rowPersonService from '../rowpeople/RowPersonService';
import { AsyncPaginate } from 'react-select-async-paginate';

class UserEditPlain extends Component {
    constructor(props) {
        super(props);
        this.state = { user: null, loading: true, warden: null };

        const { match } = this.props;
        this.userId = match.params.userId;
    }

    componentDidMount() {
        this.retrieveFormData();
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.state.user[name] = value;
        this.setState({ user: this.state.user });
    }

    handleClickCancel = () => {
        const { history } = this.props;

        history.push('/users');
    }
    handleWardenSelectChange = (e) => {
        if (e.obj) {
            this.state.user.rowPersonId = e.obj.id;
            this.setState({ warden: e.obj, user: this.state.user });
        }
    }
    clearWarden = () => {
        this.state.user.rowPersonId = null;
        this.setState({ warden: null, user: this.state.user });
    }
    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = values;
        postvalues.rowPersonId = this.state.user.rowPersonId;
        (async () => {
            await userService.updateUser(this.userId, postvalues);
            history.push('/users');
        })();
    }

    renderUserForm(user) {
        const { t, i18n } = this.props;
        return (
            <AvForm model={user} onValidSubmit={this.handleValidSubmit}>
                <AvField name="id" type="hidden" />
                <AvField name="rowVersion" type="hidden" />
                <AvField name="firstname" label={t('FirstName')} required errorMessage={t('FieldInvalid')} validate={{
                    required: { value: true, errorMessage: t('FieldRequired') },
                    minLength: { value: 2 }
                }} />
                <AvField name="surname" label="Last name" required />
                <AvField name="email" type="email" label="Email" required />
                <AvField name="role" type="select" label="Role" required>
                    <option value="Administrator">Administrator</option>
                    <option value="Warden">Warden</option>
                    <option value="Contractor">Contractor</option>
                    <option value="User">New User</option>
                </AvField>
                <FormGroup row>
                    <Label for="warden" sm={2}>Warden</Label>
                    <Col sm={6}>
                        <AsyncPaginate
                            value={this.state.warden ? { label: this.state.warden.firstname + ' ' + this.state.warden.surname, value: this.state.warden.id } : {}}
                            loadOptions={
                                async (search, loadedAssignedToOptions, { page }) => {
                                    const responseJSON = await rowPersonService.wardenselectlist(search, page, 0);
                                    return {
                                        options: responseJSON.result,
                                        hasMore: responseJSON.hasmore,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            onChange={this.handleWardenSelectChange.bind(this)}
                            additional={
                                { page: 1, }
                            } />
                    </Col>
                    <Col sm={4 }><Button onClick={this.clearWarden}>Remove warden link</Button></Col>
                </FormGroup>
                <FormGroup>
                    <Button>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </FormGroup>
            </AvForm>
        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUserForm(this.state.user);

        return (
            <div>
                <h1 id="tabelLabel">Users</h1>
                {contents}
            </div>
        );
    }

    async retrieveFormData() {
        const data = await userService.getUser(this.userId);
        const warden = data.rowPersonId !== null && data.rowPersonId !== "" ? await rowPersonService.get(data.rowPersonId) : '';
        this.setState({ user: data, loading: false, warden: warden });
    }
}

export const UserEdit = withTranslation()(withRouter(UserEditPlain));