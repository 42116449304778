import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import rowMaterialService from './RowMaterialService';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';
//import FilterManager from '../filtermanager/FilterManager';
export class RowMaterialList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            page: 1,
            pageSize: 20,
            maxPages: 15,
            sortOrder: "duedate_desc",
            searchString: "",
            loading: true,
            filters: []
        };
    }

    componentDidMount() {
        this.populateData();
    }

    handlePageChange = (page) => {
        this.setState({ page: page }, () => this.populateData());
    }

    handleHeaderClick = (event, header) => {
        event.preventDefault();

        let newSortOrder = this.state.sortOrder;

        switch (header) {
            case 'name': {
                newSortOrder = this.state.sortOrder === 'name' ? 'name_desc' : 'name';
                break;
            }
            case 'cost': {
                newSortOrder = this.state.sortOrder === 'cost' ? 'cost_desc' : 'cost';
                break;
            }
            case 'archived': {
                newSortOrder = this.state.sortOrder === 'archived' ? 'archived_desc' : 'archived';
                break;
            }
        }

        this.setState({ page: 1, sortOrder: newSortOrder }, () => this.populateData());
        return false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleSearchFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ page: 1 }, () => this.populateData());
        //this.populateData();
    }

    handleSearchFormReset = (event) => {
        event.preventDefault();
        this.setState({ page: 1, searchString: "" }, () => this.populateData());
    }
    selectMaterial = (entity) => {
        const { history } = this.props;
        history.push('/rowmaterials/edit/' + entity.id);
    }
    renderTable() {
        const { entities, total, sortOrder } = this.state;

        return (
            <div>
                <table className='table table-striped' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'name')} >
                                    Name&nbsp;
                                    {sortOrder == 'name' && <FaSortAlphaDown />}
                                    {sortOrder == 'name_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'cost')} >
                                    Cost&nbsp;
                                    {sortOrder == 'cost' && <FaSortAlphaDown />}
                                    {sortOrder == 'cost_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'archived')} >
                                    Archived&nbsp;
                                    {sortOrder == 'archived' && <FaSortAlphaDown />}
                                    {sortOrder == 'archived_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th />
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {entities.map(entity =>
                            <tr key={entity.id} style={{ cursor: 'pointer' }} onClick={() => this.selectMaterial(entity)}>
                                <td>{entity.name}</td>
                                <td>{entity.cost}</td>
                                <td>{entity.archived ? "Yes" : "No"}</td>
                                <td><Link to={'/rowmaterials/delete/' + entity.id}><FaTrash /></Link></td>
                                <td><Link to={'/rowmaterials/edit/' + entity.id}><FaEdit /></Link></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pager totalItems={total} page={this.state.page} pageSize={this.state.pageSize} maxPages={this.state.maxPages} handlePageChange={this.handlePageChange} />
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])).toLocaleDateString() : "";
    }
    
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTable();

        return (
            <div>
                <h1 id="tableLabel">Materials</h1>
                {/*<FilterManager onChange={this.filterChange} type={"rowmaterialfilters"} />*/}
                <div>&nbsp;</div>
                {/*<Link to='/rowmaterials/edit/'>Add job</Link>*/}
                <Form inline onSubmit={this.handleSearchFormSubmit}>
                    <Button size="sm" href="/rowmaterials/edit/">Add material</Button>&nbsp;
                    <FormGroup>
                        <Input type="text" name="searchString" value={this.state.searchString} onChange={this.handleInputChange} placeholder="search string" />
                    </FormGroup>&nbsp;
                    <Button size="sm">Search</Button>&nbsp;
                    <Button size="sm" onClick={this.handleSearchFormReset}>Reset</Button>&nbsp;
                </Form><br />
                {contents}
            </div>
        );
    }
    filterChange = (filters) => {
        this.setState({ filters: filters });
        this.setState({ page: 1 }, () => this.populateData());
    }
    async populateData() {
        try {

            const data = await rowMaterialService.getData(
                this.state.page,
                this.state.pageSize,
                this.state.sortOrder,
                this.state.searchString,
                this.state.filters
            );
            
            this.setState({ total: data.total, entities: data.entities, loading: false });
        }
        catch (error) {
            // Handle error
        }
    }
}