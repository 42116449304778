import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
//import { RowMaterialCalendar } from '../workprogramcalendar/RowMaterialCalendar';
import { RowWardenAreaList } from './RowWardenAreaList';


export function RowWardenAreaRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			<AuthorizeRoute path={`${match.path}`} component={RowWardenAreaList} />
		</Switch>
	);
}