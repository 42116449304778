import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import bridgeInspectionService from './BridgeInspectionService';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';
import FilterManager from '../filtermanager/FilterManager';
export class BridgeInspectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            page: 1,
            pageSize: 20,
            maxPages: 15,
            sortOrder: "inspectiondate_desc",
            searchString: "",
            loading: true,
            filters: []
        };
    }

    componentDidMount() {
        this.populateData();
    }

    handlePageChange = (page) => {
        this.setState({ page: page }, () => this.populateData());
    }

    handleHeaderClick = (event, header) => {
        event.preventDefault();

        let newSortOrder = this.state.sortOrder;

        switch (header) {
            case 'code': {
                newSortOrder = this.state.sortOrder === 'code' ? 'code_desc' : 'code';
                break;
            }
            case 'name': {
                newSortOrder = this.state.sortOrder === 'name' ? 'name_desc' : 'name';
                break;
            }
            case 'inspectiondate': {
                newSortOrder = this.state.sortOrder === 'inspectiondate' ? 'inspectiondate_desc' : 'inspectiondate';
                break;
            }
            case 'watercourse': {
                newSortOrder = this.state.sortOrder === 'watercourse' ? 'watercourse_desc' : 'watercourse';
                break;
            }
            case 'warden': {
                newSortOrder = this.state.sortOrder === 'warden' ? 'warden_desc' : 'warden';
                break;
            }
        }

        this.setState({ page: 1, sortOrder: newSortOrder }, () => this.populateData());
        return false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleSearchFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ page: 1 }, () => this.populateData());
        //this.populateData();
    }

    handleSearchFormReset = (event) => {
        event.preventDefault();
        this.setState({ page: 1, searchString: "" }, () => this.populateData());
    }
    selectInspection = (entity) => {
        const { history } = this.props;
        history.push('/bridgeinspections/edit/' + entity.id);

    }
    renderTable() {
        const { entities, total, sortOrder } = this.state;

        return (
            <div>
                <table className='table table-striped' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'code')} >
                                    Code&nbsp;
                                    {sortOrder == 'code' && <FaSortAlphaDown />}
                                    {sortOrder == 'code_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'name')} >
                                    Name&nbsp;
                                    {sortOrder == 'name' && <FaSortAlphaDown />}
                                    {sortOrder == 'name_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'warden')} >
                                    Warden&nbsp;
                                    {sortOrder == 'warden' && <FaSortAlphaDown />}
                                    {sortOrder == 'warden_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'inspectiondate')} >
                                    Date&nbsp;
                                    {sortOrder == 'inspectiondate' && <FaSortAlphaDown />}
                                    {sortOrder == 'inspectiondate_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                          
                            <th />
                            
                        </tr>

                    </thead>
                    <tbody>
                        {entities.map(entity => {
                            return [
                                (<tr key={entity.id} style={{ cursor: 'pointer' }} onClick={() => this.selectInspection(entity)} >
                                    <td>{entity.bridge.code}</td>
                                    <td>{entity.bridge.name}</td>
                                    <td>{entity.warden ? entity.warden.firstname + ' ' + entity.warden.surname : ''}</td>
                                    <td>{this.fixDate(entity.inspectionDate)}</td>
                                    <td><Link to={'/bridgeinspections/edit/' + entity.id}><FaEdit /></Link></td>
                                </tr>),(
                                 entity.notes !== "" ? 
                                        <tr>
                                            <td colspan="9">{entity.notes}</td>
                                        </tr>
                                        : ''
                                )
                            ]
                        }



                        )}
                    </tbody>
                </table>
                <Pager totalItems={total} page={this.state.page} pageSize={this.state.pageSize} maxPages={this.state.maxPages} handlePageChange={this.handlePageChange} />
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])).toLocaleDateString() : "";
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTable();

        return (
            <div>
                <h1 id="tableLabel">Bridge Inspections</h1>
                <FilterManager onChange={this.filterChange} type={"bridgeinspectionfilters"} />
                
                <div>&nbsp;</div>
                <Form inline onSubmit={this.handleSearchFormSubmit}>
                    <Button size="sm" href="/bridgeinspections/add/">Add inspection</Button>&nbsp;
                    <FormGroup>
                        <Input type="text" name="searchString" value={this.state.searchString} onChange={this.handleInputChange} placeholder="search string" />
                    </FormGroup>&nbsp;
                    <Button size="sm">Search</Button>&nbsp;
                    <Button size="sm" onClick={this.handleSearchFormReset}>Reset</Button>&nbsp;
                </Form><br />
                {contents}
            </div>
        );
    }
    filterChange = (filters) => {
        this.setState({ filters: filters });
        this.setState({ page: 1 }, () => this.populateData());
    }
    async populateData() {
        try {

            const data = await bridgeInspectionService.getData(
                this.state.page,
                this.state.pageSize,
                this.state.sortOrder,
                this.state.searchString,
                this.state.filters
            );
            //data.entities.forEach(function (item) {
            //    var location = item.location;
            //    //item.location = location.coordinates;
            //    var leader = item.leader;
            //    if (leader) {
            //        item.leader = leader.firstname + " " + leader.surname;
            //        item.leaderid = leader.id;
            //    } else {
            //        item.leader = "";
            //    }

            //});
            this.setState({ total: data.total, entities: data.entities, loading: false });
        }
        catch (error) {
            // Handle error
        }
    }
}