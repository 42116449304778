import React, { Component } from 'react';
import { withRouter } from 'react-router';
import rowMaterialService from './RowMaterialService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Card, CardBody, CardText, CardHeader, CardTitle, CustomInput, Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { SelectFetch } from 'react-select-fetch';
import { AsyncPaginate } from 'react-select-async-paginate';

import authService from '../api-authorization/AuthorizeService';

import { wgs84, osgb } from "../osgb";



class RowMaterialEditPlain extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            entity: null,
            loading: true,
            gridref: '',
            locked: false,
            isnew: false,
            duplicates: []
        };

        const { match } = this.props;
        this.id = match.params.id;
    }




    async getSelectOptions() {

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();

    }
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        this.setState({ ready: true, isAuthenticated: authenticated, user, role: user && user.role, isAdmin: user.role.includes("Administrator") });
        this.userStateReturned();
    }
    userStateReturned() {
        this.retrieveFormData();
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;

        this.setState({ entity: this.state.entity });
    }

    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = this.state.entity;
        if (postvalues.completedDate === "") {
            postvalues.completedDate = null;
        }
        if (postvalues.dueDate === "") {
            postvalues.dueDate = null;
        }
        if (this.state.isnew) {
            (async () => {
                await rowMaterialService.add(postvalues);
                //history.push('/rowmaterials');
                history.goBack();
            })();
        } else {
            (async () => {
                await rowMaterialService.update(this.id, postvalues);
                //history.push('/rowmaterials');
                history.goBack();
            })();
        }

    }
    handleArchivedChange = (e) => {
        this.state.entity.archived = e.target.checked;
        this.setState({ entity: this.state.entity });
    }
    handleMergeDuplicateToggle = (e) => {
        var duplicates = [];
        for (var duplicate of this.state.duplicates) {
            if (duplicate.id == e.target.name) {
                if (duplicate.merge) {
                    duplicate.merge = !duplicate.merge
                } else {
                    duplicate.merge = true;
                }
            }
            duplicates.push(duplicate);
        }
        this.setState({ duplicates: duplicates });
    }
    mergeDuplicates = () => {
        let duplicateids = [];
        for (var duplicate of this.state.duplicates) {
            if (duplicate.merge) {
                duplicateids.push(duplicate.id);
            }
        }
        (async () => {
            await rowMaterialService.mergeduplicates(this.id, duplicateids);
            let duplicates = await rowMaterialService.duplicates(this.id);
            this.setState({ duplicates: duplicates })
        })();
    }
    renderForm(entity) {
        const { t, i18n } = this.props;
        return (

            <Form onSubmit={this.handleValidSubmit}>
                <Card>
                    <CardBody>
                        <FormGroup row>
                            <Label for="name" sm={2}>Name</Label>
                            <Col sm={10}>
                                <Input type="text" name="name" value={entity.name} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="cost" sm={2}>Cost</Label>
                            <Col sm={10}>
                                <Input type="number" name="cost" value={entity.cost} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <CustomInput checked={entity.archived} type="switch" id="archived" name="archived" label="Archived" onChange={this.handleArchivedChange} />
                    </CardBody>
                </Card>
                <div>&nbsp;</div>
                {this.state.isnew ? '' : this.state.duplicates.length > 0 ? (
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>Select to merge</Col>
                                <Col>Name</Col>
                                <Col>Cost</Col>
                            </Row>
                            {this.state.duplicates.map(duplicate => (
                                <Row>
                                    <Col><CustomInput checked={duplicate.merge ? duplicate.merge : false } type="switch" id={duplicate.id} name={duplicate.id} label="Merge" onChange={this.handleMergeDuplicateToggle} /></Col>
                                    <Col>{duplicate.name }</Col>
                                    <Col>{duplicate.cost }</Col>
                                </Row>
                            ))}
                            <div>&nbsp;</div>
                            <Button onClick={this.mergeDuplicates} size="sm">Merge selected duplicates</Button>
                        </CardBody>
                    </Card>
                ) : ''}
                <div>&nbsp;</div>
                <FormGroup>
                    <Button onClick={this.handleValidSubmit}>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </FormGroup>
            </Form>

        );
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm(this.state.entity);

        return (
            <div>
                <h1 id="tabelLabel">Material</h1>
                {contents}
            </div>
        );
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    async retrieveFormData() {
        let isnew = false;
        let data = {}
        let duplicates = [];
        if (this.id) {
            data = await rowMaterialService.get(this.id);
            duplicates = await rowMaterialService.duplicates(this.id);
        }
        else {
            data = {
                id: this.uuidv4(),
                name: '',
                cost: '',
                archived:false
            }
            isnew = true;
        }

        this.setState({ entity: data, loading: false, isnew: isnew, duplicates: duplicates });
    }
}

export const RowMaterialEdit = withTranslation()(withRouter(RowMaterialEditPlain));