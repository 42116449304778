import React, { Component } from 'react';
import {
    Card, CardBody, CardText, CardHeader, CardTitle, CustomInput,
    Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText, CardFooter
} from 'reactstrap';
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import rowPersonService from '../rowpeople/RowPersonService';
import rowStatusService from '../rowstatuses/RowStatusService';
import { FaTrash } from 'react-icons/fa';

class WorksEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: props.value
        };
    }
    handleChange = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.entity);
        }
    }
    handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;
        this.setState({ entity: this.state.entity });
        this.handleChange();
    }
    handleStatusSelectChange(e) {
        this.state.entity["status"] = e.obj;
        this.setState({ entity: this.state.entity });
        this.handleChange();
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    handleAddWardenWork = () => {
        let newwork = [
            ...this.state.entity.wardenWorks,
            {
                id: this.uuidv4(),
                hours: 0,
                hourlyRate: 0.00,
                warden: null,
                index: this.state.entity.wardenWorks.length
            }];
        this.state.entity.wardenWorks = newwork;
        this.setState({ entity: this.state.entity });
        if (this.props.onChange) {
            this.props.onChange(this.state.entity);
        }
    }
    handleAddContractorWork = () => {
        let newwork = [
            ...this.state.entity.contractorWorks,
            {
                id: this.uuidv4(),
                hours: 0,
                hourlyRate: 0.00,
                person: null,
                index: this.state.entity.contractorWorks.length
            }];
        this.state.entity.contractorWorks = newwork;
        this.setState({ entity: this.state.entity });
        if (this.props.onChange) {
            this.props.onChange(this.state.entity);
        }
    }
    handleDeleteWardenWork = (k) => {
        this.state.entity.wardenWorks = [...this.state.entity.wardenWorks.slice(0, k), ...this.state.entity.wardenWorks.slice(k + 1)];
        for (var i = 0; i < this.state.entity.wardenWorks.length; i++) {
            this.state.entity.wardenWorks[i].index = i;
        }
        this.setState({ entity: this.state.entity });
        if (this.props.onChange) {
            this.props.onChange(this.state.entity);
        }
    }
    handleDeleteContractorWork = (k) => {
        this.state.entity.contractorWorks = [...this.state.entity.contractorWorks.slice(0, k), ...this.state.entity.contractorWorks.slice(k + 1)];
        for (var i = 0; i < this.state.entity.contractorWorks.length; i++) {
            this.state.entity.contractorWorks[i].index = i;
        }
        this.setState({ entity: this.state.entity });
        if (this.props.onChange) {
            this.props.onChange(this.state.entity);
        }
    }
    totalCost() {
        let tot = 0.0;
        for (var mat of this.state.entity.materials) {
            tot = tot + (mat.cost * mat.quantity)
        }
        tot = tot + (this.state.entity.volunteerHourlyRate * this.state.entity.volunteerHours);
        for (var work of this.state.entity.wardenWorks) {
            tot = tot + (work.hours * work.hourlyRate)
        }
        for (var work of this.state.entity.contractorWorks) {
            tot = tot + (work.hours * work.hourlyRate)
        }
        return parseFloat(tot).toFixed(2);
    }
    render() {
        return (
            <Card>
                <CardHeader>Work and Status</CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label for="" sm={5}>Status</Label>
                                <Col sm={7}>
                                    <AsyncPaginate
                                        value={this.state.entity.status ? { label: this.state.entity.status.name, value: this.state.entity.status.id } : {}}
                                        loadOptions={
                                            async (search, loadedJobPriorityOptions, { page }) => {
                                                const responseJSON = await rowStatusService.selectlist(search, page, 0);


                                                return {
                                                    options: this.props.isAdmin == true ?
                                                        responseJSON.result
                                                        :
                                                        responseJSON.result.filter(function (r) { if (r.label !== "Complete") return true }),
                                                    hasMore: responseJSON.hasmore,
                                                    additional: {
                                                        page: page + 1,
                                                    },
                                                };
                                            }}
                                        onChange={this.handleStatusSelectChange.bind(this)}
                                        additional={
                                            { page: 1, }
                                        }
                                        readOnly={this.props.readOnly} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Label for="completedDate" sm={5}>Date Completed</Label>
                                <Col sm={7}>
                                    <Input type="date" name="completedDate" value={this.state.entity.completedDate} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup row>
                        <Label for="wardensComments" sm={2}>Wardens Comments</Label>
                        <Col sm={10}>
                            <Input type="textarea" name="wardensComments" value={this.state.entity.wardensComments} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                        </Col>
                    </FormGroup>

                    <Row>
                        <Col sm={4}>
                            <FormGroup row>
                                <Label for="volunteerHours" sm={6}>Volunteer Hours</Label>
                                <Col sm={6}>
                                    <Input type="number" name="volunteerHours" value={this.state.entity.volunteerHours} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={4}>
                            <FormGroup row>
                                <Label for="" sm={6}>Hourly Rate</Label>
                                <Col sm={6}>
                                    <Input type="number" name="volunteerHourlyRate" value={this.state.entity.volunteerHourlyRate} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup row>
                                <Label for="" sm={4} style={{ textAlign: "right" }}>=&pound;</Label>
                                <Col sm={8}>
                                    <Input type="number" value={parseFloat(this.state.entity.volunteerHours * this.state.entity.volunteerHourlyRate).toFixed(2)} readOnly={true} />
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col sm={1}></Col>
                    </Row>
                    {
                        this.state.entity.wardenWorks.map((work, index) => (
                            <div>
                                <hr />
                                <WardenWorksLineEditor key={work.id ? work.id : ''} index={index} value={work} onDelete={this.handleDeleteWardenWork} onChange={this.handleChange} />
                            </div>
                        ))
                    }
                    {
                        this.state.entity.contractorWorks.map((work, index) => (
                            <div>
                                <hr />
                                <ContractorWorksLineEditor key={work.id ? work.id : ''} index={index} value={work} onDelete={this.handleDeleteContractorWork} onChange={this.handleChange} />
                            </div>
                        ))
                    }
                </CardBody>
                <CardFooter>
                    <Button onClick={this.handleAddWardenWork} disabled={this.props.readOnly}>Add Warden Work</Button>&nbsp;
                    <Button onClick={this.handleAddContractorWork} disabled={this.props.readOnly}>Add Contractor Work</Button>
                    <div style={{ float: "right" }}>Total work and material cost = &pound;{this.totalCost()}</div>
                </CardFooter>
            </Card>
        );
    }
}
class WardenWorksLineEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            work: props.value
        }
    }
    handleChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state.work, this.props.index);
        }
    }
    handleDeleteClick = () => {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.index);
        }
    }
    handleWardenSelectChange(e) {
        if (e.obj) {
            this.state.work["warden"] = e.obj;
            this.state.work["hourlyRate"] = e.obj.hourlyRate;
            this.setState({ work: this.state.work });
            this.handleChange();
        }
    }
    handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.work[name] = value;
        this.setState({ work: this.state.work });
        this.handleChange();
    }
    render() {
        return (
            <Row>
                <Col sm={3}>
                    <FormGroup row>
                        <Label for="warden" sm={6}>Warden</Label>
                        <Col sm={6}>
                            <AsyncPaginate
                                value={this.state.work.warden ? { label: this.state.work.warden.firstname + ' ' + this.state.work.warden.surname, value: this.state.work.warden.id } : {}}
                                loadOptions={
                                    async (search, loadedWardenOptions, { page }) => {
                                        const responseJSON = await rowPersonService.wardenselectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleWardenSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                                readOnly={this.props.readOnly} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup row>
                        <Label for="hours" sm={6}>Hours</Label>
                        <Col sm={6}>
                            <Input type="number" name="hours" value={this.state.work.hours} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup row>
                        <Label for="" sm={6}>Rate</Label>
                        <Col sm={6}>
                            <Input type="number" name="hourlyRate" value={this.state.work.hourlyRate} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup row>
                        <Label for="" sm={4} style={{ textAlign: "right" }}>=&pound;</Label>
                        <Col sm={8}>
                            <Input type="number" value={parseFloat(this.state.work.hours * this.state.work.hourlyRate).toFixed(2)} readOnly={true} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={1}>
                    <Button onClick={this.handleDeleteClick} disabled={this.props.readOnly} style={{ float: "right" }}><FaTrash /></Button>
                </Col>
            </Row>
        );
    }
}
class ContractorWorksLineEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            work: props.value
        }
    }
    handleChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state.work, this.props.index);
        }
    }
    handleDeleteClick = () => {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.index);
        }
    }
    handleWardenSelectChange(e) {
        if (e.obj) {
            this.state.work["person"] = e.obj;
            this.setState({ work: this.state.work });
            this.handleChange();
        }
    }
    handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.work[name] = value;
        this.setState({ work: this.state.work });
        this.handleChange();
    }
    render() {
        return (
            <Row>
                <Col sm={4}>
                    <FormGroup row>
                        <Label for="hours" sm={6}>Contractor Hours</Label>
                        <Col sm={6}>
                            <Input type="number" name="hours" value={this.state.work.hours} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={4}>
                    <FormGroup row>
                        <Label for="" sm={6}>Hourly Rate</Label>
                        <Col sm={6}>
                            <Input type="number" name="hourlyRate" value={this.state.work.hourlyRate} onChange={this.handleInputChange} readOnly={this.props.readOnly} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={3}>
                    <FormGroup row>
                        <Label for="" sm={4} style={{ textAlign: "right" }}>=&pound;</Label>
                        <Col sm={8}>
                            <Input type="number" value={parseFloat(this.state.work.hours * this.state.work.hourlyRate).toFixed(2)} readOnly={true} />
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm={1}>
                    <Button onClick={this.handleDeleteClick} disabled={this.props.readOnly} style={{ float: "right" }}><FaTrash /></Button>
                </Col>
            </Row>
        );
    }
}

export default WorksEditor;