import React, { Component } from 'react';
import { withRouter } from 'react-router';
import bridgeService from './BridgeService';

import rowPersonService from '../rowpeople/RowPersonService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Card, CardBody, CardText, CardHeader, CardTitle, CustomInput, Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { SelectFetch } from 'react-select-fetch';
import { AsyncPaginate } from 'react-select-async-paginate';
import userService from '../users/UserService';
import authService from '../api-authorization/AuthorizeService';
import GridrefPointInputPopupSelector from '../pathmap/GridrefPointPopupSelector';

import { wgs84, osgb } from "../osgb";



class BridgeEditPlain extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            entity: null,
            loading: true,
            gridref: '',
            locked: false,
            isnew:false
        };

        const { match } = this.props;
        this.id = match.params.id;
    }

    

    handlePriorityLevelSelectChange(e) {
        this.state.entity["priorityLevel"] = e.obj;
        if (!this.state.entity.priorityOverridden) {
            //try {
                this.state.entity.dueDate = this.fixDate(
                    this.addMonths(
                        new Date(this.state.entity.reportedDate),
                        e.obj.months).toISOString()
                );
            //} catch (ex) { }
        }
        this.setState({ entity: this.state.entity });
    }
    handleReportedBySelectChange(e) {
        this.state.entity["reportedBy"] = e.obj;
        this.setState({ entity: this.state.entity });
    }
    handleAssignedToSelectChange(e) {
        this.state.entity["assignedTo"] = e.obj;
        this.setState({ entity: this.state.entity });
    }
    async getSelectOptions() {

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();

    }
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        this.setState({ ready: true, isAuthenticated: authenticated, user, role: user && user.role, isAdmin: user.role.includes("Administrator") });
        this.userStateReturned();
    }
    userStateReturned() {
        this.retrieveFormData();
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;
        if (!this.state.entity.priorityOverridden && this.state.entity.priorityLevel) {
            this.state.entity.dueDate = this.fixDate(this.addMonths(new Date(this.state.entity.reportedDate), this.state.entity.priorityLevel.months).toISOString());
        }
        //console.log("name=" + name + ",val=" + value + ",ov=" + this.state.entity[name] + ", tt=" + target.type);
        this.setState({ entity: this.state.entity });
        if (name === "prow") {
            this.getPriorityForProw(value);
        }
    }
    handleLocationChange = (data) => {
        let newentity = this.state.entity;
        newentity.location = {
            "type": "Point",
            //"coordinates": [data.easting, data.northing]
            "coordinates": [data.lng, data.lat]
        }
        newentity.lng = data.lng;
        newentity.lat = data.lat;
        newentity.prow = data.prow;
        newentity.easting = data.easting;
        newentity.northing = data.northing;
        this.setState({ entity: newentity, gridref: data.gridref })
        this.getNearestObjects(data.easting, data.northing)
        this.getPriorityForProw(data.prow);
    }
    
    async getNearestObjects(easting, northing) {
        try {
            const data = await bridgeService.nearestobjects(northing, easting);
            //console.log(data);
            let newentity = this.state.entity;

            newentity.community = data.community;
            newentity.parish = data.parish;
            newentity.county = data.county;
            if (!this.state.entity.assignedTo) {
                newentity.assignedTo = data.warden;
            }
            // warden
            this.setState({ entity: newentity });
        }
        catch (error) {
            console.log(error);
            // Handle error
        }
    }
    setGridref(easting, northing) {
        this.osgb.setGridCoordinates(easting, northing);
        this.setState({ gridref: this.osgb.getGridRef(6) });
    }
    handleNorthingChange = (e) => { }
    handleEastingChange = (e) => { }
    handleGridrefChange = (e) => { }
    handleClickCancel = () => {
        const { history } = this.props;

        //history.push('/rowjobs');
        history.goBack();
    }

    handleMaterialsChange = (data) => {
        let newentity = this.state.entity;
        newentity.materials = data;
        this.setState({ entity: newentity });
    }
    handleWorksChange = (data) => {
        this.setState({ entity: data });
    }
    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = this.state.entity;
        if (postvalues.completedDate === "") {
            postvalues.completedDate = null;
        }
        if (postvalues.dueDate === "") {
            postvalues.dueDate = null;
        }
        if (this.state.isnew) {
            (async () => {
                await bridgeService.add( postvalues);
                //history.push('/rowjobs');
                history.goBack();
            })();
        } else {
            (async () => {
                await bridgeService.update(this.id, postvalues);
                //history.push('/rowjobs');
                history.goBack();
            })();
        }
        
    }

    renderForm(entity) {
        const { t, i18n } = this.props;
        return (

            <Form onSubmit={this.handleValidSubmit}>
                <Card>
                    <CardHeader>Job Location and Details</CardHeader>
                    <CardBody>
                        <FormGroup row>
                            <Label for="jobNumber" sm={2}>Job Number</Label>
                            <Col sm={10}>
                                <Input type="text" name="jobNumber" value={entity.jobNumber} onChange={this.handleInputChange} readOnly />
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label for="" sm={4}>Easting</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="easting" value={entity.easting == null ? '' : entity.easting} onChange={this.handleEastingChange} readOnly={this.state.locked} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label for="" sm={4}>Northing</Label>
                                    <Col sm={8}>
                                        <Input type="number" name="northing" value={entity.northing == null ? '' : entity.northing} onChange={this.handleNorthingChange} readOnly={this.state.locked} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label for="" sm={4}>Gridref</Label>
                                    <Col sm={8}>
                                        <Input type="text" name="gridref" value={this.state.gridref} onChange={this.handleGridrefChange} readOnly={this.state.locked} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <GridrefPointInputPopupSelector value={entity.location} onChange={this.handleLocationChange} readOnly={this.state.locked} />
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label for="" sm={5}>PROW Number</Label>
                                    <Col sm={7}>
                                        <Input type="text" name="prow" value={entity.prow} onChange={this.handleInputChange} readOnly={this.state.locked} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Label for="description" sm={2}>Description</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="description" value={entity.description} onChange={this.handleInputChange} readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="landOwnerDetails" sm={2}>Landowner Details</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="landOwnerDetails" value={entity.landOwnerDetails} onChange={this.handleInputChange} readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
                <Card>
                    <CardHeader>Reported By and Dates</CardHeader>
                    <CardBody>
                        <div>
                            <FormGroup check inline>
                                <Label check >
                                    <Input type="radio" name="externallyReported" value="false" onChange={this.handleInputChange} checked={!entity.externallyReported} disabled={this.state.locked} />{' '}
                                    Internal
                                </Label>
                            </FormGroup>
                            <FormGroup check inline>
                                <Label check >
                                    <Input type="radio" name="externallyReported" value="true" onChange={this.handleInputChange} checked={entity.externallyReported} disabled={this.state.locked} />{' '}
                                    External
                                </Label>
                            </FormGroup>
                        </div>

                        <div style={{ display: entity.externallyReported ? "" : "none" }}>
                            <FormGroup row>
                                <Label for="reportedBy" sm={2}>Reported By</Label>
                                <Col sm={10}>
                                    <AsyncPaginate
                                        value={entity.reportedBy ? { label: entity.reportedBy.firstname + ' ' + entity.reportedBy.surname, value: entity.reportedBy.id } : {}}
                                        loadOptions={
                                            async (search, loadedReportedByOptions, { page }) => {
                                                const responseJSON = await rowPersonService.selectlist(search, page, 0);
                                                return {
                                                    options: responseJSON.result,
                                                    hasMore: responseJSON.hasmore,
                                                    additional: {
                                                        page: page + 1,
                                                    },
                                                };
                                            }}
                                        onChange={this.handleReportedBySelectChange.bind(this)}
                                        additional={
                                            { page: 1, }
                                        }
                                        readOnly={this.state.locked} />
                                </Col>
                            </FormGroup>
                            {entity.reportedBy ? (
                                <div>
                                    <FormGroup row>
                                        <Label for="reportedByPhone" sm={2}>Phone Number</Label>
                                        <Col sm={10}>
                                            <Input type="text" value={entity.reportedBy.phone} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="reportedByEmail" sm={2}>Email</Label>
                                        <Col sm={10}>
                                            <Input type="text" value={entity.reportedBy.email} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="reportedByAddress" sm={2}>Address</Label>
                                        <Col sm={10}>
                                            <Input type="text" value={''} />
                                        </Col>
                                    </FormGroup><FormGroup row>
                                        <Label for="reportedByPostcode" sm={2}>Postcode</Label>
                                        <Col sm={10}>
                                            <Input type="text" value={''} />
                                        </Col>
                                    </FormGroup>
                                </div>
                            ) : ""}

                        </div>


                        <FormGroup row>
                            <Label for="reportedDate" sm={2}>Date Reported</Label>
                            <Col sm={10}>
                                <Input type="date" name="reportedDate" value={entity.reportedDate} onChange={this.handleInputChange} readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="dueDate" sm={2}>Due Date</Label>
                            <Col sm={10}>
                                <Input type="date" name="dueDate" value={entity.dueDate} onChange={this.handleInputChange} readOnly={!entity.priorityOverridden || this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="priorityOverridden" sm={3}>Priority Overridden</Label>
                            <Col sm={6} inline>
                                <Input type="checkbox" name="priorityOverridden" value={true} checked={entity.priorityOverridden} onChange={this.handleInputChange} readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="assignedTo" sm={2}>Assigned To</Label>
                            <Col sm={10}>
                                <AsyncPaginate
                                    value={entity.assignedTo ? { label: entity.assignedTo.firstname + ' ' + entity.assignedTo.surname, value: entity.assignedTo.id } : {}}
                                    loadOptions={
                                        async (search, loadedAssignedToOptions, { page }) => {
                                            const responseJSON = await rowPersonService.wardenselectlist(search, page, 0);
                                            return {
                                                options: responseJSON.result,
                                                hasMore: responseJSON.hasmore,
                                                additional: {
                                                    page: page + 1,
                                                },
                                            };
                                        }}
                                    onChange={this.handleAssignedToSelectChange.bind(this)}
                                    additional={
                                        { page: 1, }
                                    }
                                    readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
                
                <FormGroup row>
                    <Label for="" sm={2}> </Label>
                    <Col sm={10}>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Button onClick={this.handleValidSubmit}>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </FormGroup>
            </Form>
            
        );
    }
    addMonths(dateObj, num) {

        var currentMonth = dateObj.getMonth() + dateObj.getFullYear() * 12;
        dateObj.setMonth(dateObj.getMonth() + num);
        var diff = dateObj.getMonth() + dateObj.getFullYear() * 12 - currentMonth;

        // If don't get the right number, set date to 
        // last day of previous month
        if (diff != num) {
            dateObj.setDate(0);
        }

        return dateObj;
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm(this.state.entity);

        return (
            <div>
                <h1 id="tabelLabel">Work Order</h1>
                {contents}
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? date.split('T')[0] : "";
    }
    async retrieveFormData() {
        let isnew = false;
        let data = {}
        if (this.id) {
            data = await bridgeService.get(this.id);
        }
        else {
            data = await bridgeService.newjob();
            isnew = true;
        }

        data.reportedDate = data.reportedDate != null && data.reportedDate != "0001-01-01T00:00:00" && data.reportedDate.length > 0 ? data.reportedDate.split('T')[0] : (new Date()).toISOString().split('T')[0];
        data.dueDate = this.fixDate(data.dueDate);
        data.completedDate = this.fixDate(data.completedDate);

        if (data.priorityLevel) {
            data.priorityLevelValue = { label: data.priorityLevel.prowPriorityId, value: data.priorityLevel.id }
        }
        if (data.location && data.location.coordinates) {
            this.setGridref(data.easting, data.northing)
        }
        
        data.materials.sort((a, b) => a.index > b.index ? 1 : b.index > a.index ? -1 : 0);
        data.wardenWorks.sort((a, b) => a.index > b.index ? 1 : b.index > a.index ? -1 : 0);
        data.contractorWorks.sort((a, b) => a.index > b.index ? 1 : b.index > a.index ? -1 : 0);

        //data.start = data.start != null && data.start.length > 0 ? data.start.split('T')[0] : "";
        //data.end = data.end != null && data.end.length > 0 ? data.end.split('T')[0] : "";
        //data.leadervalue = data.leader == null ? null : {"label": data.leader.firstname + " " + data.leader.surname, "value": data.leader.id }

        //let going = [];
        //for (let person of data.going) {
        //    going.push({ value: person.id, label: person.firstname + " " + person.surname });
        //}
        //data.goingvalue = going;
        //if (data.pathState) {
        //    data.pathstatevalue = { label: data.pathState.state, value: data.pathState.id };
        //}
        //if (data.workRequired) {
        //    data.workrequiredvalue = { label: data.workRequired.state, value: data.workRequired.id };
        //}
        //if (data.path) {
        //    data.pathvalue = { label: data.path.name, value: data.path.id };
        //}
        //if (data.volunteerGroup) {
        //    data.volunteergroupvalue = { label: data.volunteerGroup.name, value: data.volunteerGroup.id, colour: data.volunteerGroup.colour  };
        //}
        this.setState({ entity: data, loading: false,isnew:isnew });
    }
}

export const BridgeEdit = withTranslation()(withRouter(BridgeEditPlain));