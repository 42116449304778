import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import pathService from '../paths/PathService';
import { Modal, ModalHeader,ModalBody, Button, FormGroup, Input, Label } from "reactstrap";
import rowJobService from '../rowjobs/RowJobService';
//import rowStatusService from '../rowstatuses/RowStatusService';
import rowWardenAreaService from '../rowwardenareas/RowWardenAreaService';
import {
    Map,
    WMSTileLayer,
    LayersControl,
    Polyline,
    Popup,
    Marker,
    Tooltip,
    AutoOpenPopupMarker,
    withLeaflet,
    ZoomControl,
    Polygon
} from "react-leaflet";
const { BaseLayer, Overlay } = LayersControl;
//import L, { Tooltip } from "leaflet";

//delete L.Icon.Default.prototype._getIconUrl;

//L.Icon.Default.mergeOptions({
//    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//    iconUrl: require('leaflet/dist/images/marker-icon.png'),
//    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
//});

class JobMap extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            selectedfeature: null,
            selectedgridref: null,
            selectedlocation: null,
            filter: props.filter,
            jobs: [],
            wardenareas: [],
            loading: true,
            visible:false
        };
        //this.loadWardenAreas();
        
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.filter != this.state.filter) {
            console.log("1");
        }
        if (nextProps.visible) {
            console.log("2");
            this.setState({ visible: true });
            this.populateData();
        }
        else {
            this.setState({ visible: false });
        }
    }
    async populateData() {
        try {
            const data = await rowJobService.getData(
                0,
                -1,
                this.props.sortOrder,
                this.props.searchString,
                this.props.filters
            );
            //const wardenAreas = await rowWardenAreaService.list();
            //data.entities.forEach(function (item) {
            //});
            this.setState({ jobs: data.entities, loading: false });
            console.log(data.total);
        } catch (ex) { }
    }
    async loadWardenAreas() {
        try {
            const wardenAreas = await rowWardenAreaService.list();
            //data.entities.forEach(function (item) {
            //});
            console.log(wardenAreas);
            this.setState({ wardenareas: wardenAreas.result });
        } catch (ex) { }
    }
    onNextFrame() {
        var _this = this;
        window.requestAnimationFrame(function () {
            var domnode = document.querySelector(".ab75a991-a956-42a5-9704-29c031a32861");//_this.getDOMNode();
            if (domnode !== undefined) {
                console.log(domnode);
                // set your attributes here
                /*jwplayer(domnode).setup({
                    "file": "http://example.com/myVideo.mp4",
                    "image": "http://example.com/myImage.png",
                    "height": 360,
                    "width": 640
                });*/
            }
        });
    }
    componentDidMount() { }
    componentDidUpdate() { }
    renderWardenAreas() {
        // [wardens].coordinates[].polygons[].coordinates[].coordinate[]
        let wardenareas = [];
        let ii = 0;
        //[wardens]
        for (var wardenarea of this.state.wardenareas) {
            if (ii++ < 10) {
                let multipoly = [];
                // wardens[].coordinates[]
                for (var wardenpoly of wardenarea.area.coordinates) {
                    //wardenarea.area.coordinates
                    // wardens[].coordinates[].polygons[]
                    let poly = [];
                    for (var coord of wardenpoly[0]) {
                        //wardenarea.area.coordinates.[poly's]
                        this.osgb.eastings = coord[0];
                        this.osgb.northings = coord[1];
                        let wgs84ref = this.osgb.getWGS84();
                        if (wgs84ref.latitude) {
                            poly.push([wgs84ref.latitude, wgs84ref.longitude]);
                        } else {
                            //console.log("'" + coord[0] + "' - '" + coord[1] + "'");
                        }
                    };
                    multipoly.push(poly);
                };

                wardenareas.push(
                    <Polygon
                        className={'wapath' + wardenarea.id}
                        positions={multipoly}
                        onClick={this.onWardenAreaClick}
                    />
                );
            }
        };
        return wardenareas;
    }
    renderJobs() {
        let jobmarkers = [];
        const openInNewTab = url => {
            window.open(url, '_blank', 'noopener,noreferrer');
        };
        this.state.jobs.forEach(function (job) {
            if (job.location) {
                jobmarkers.push(
                    <Marker position={[job.location.coordinates[1],
                    job.location.coordinates[0]]}>
                        <Popup>
                            <p>Job Number : {job.jobNumber}</p>
                            <p>Description : {job.description}</p>
                            <p>Assigned To : {job.assignedTo ? job.assignedTo.firstname + " " + job.assignedTo.surname : ''}</p>
                            <Button onClick={() => openInNewTab('/rowjobs/edit/' + job.id)}>Open</Button>
                        </Popup>
                        <Tooltip permanent>{job.jobNumber}</Tooltip>
                    </Marker>

                );
            }
        });
        return jobmarkers;
        //
    }
    onWardenAreaClick = (e) => {
        
    }
    onAfterRender = () => {
        let colors = ['red', 'blue', 'green', 'cyan', 'orange', 'CornflowerBlue', 'violet', 'purple']
        let colorindex = 0;
        for (var wardenarea of this.state.wardenareas) {
            let id = ".wapath" + wardenarea.id.trim();
            let path = document.querySelector(id); // ab75a991-a956-42a5-9704-29c031a32861
            if (path) {
                path.setAttribute("stroke", colors[colorindex]);
                path.setAttribute("fill", colors[colorindex]);
                //path.onClick = this.onWardenAreaClick;
            }
            colorindex++;
        }
    }
    onHide = () => {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    render() {
        let wgs84ref = null;
        let invalid = false;
        try {
            //this.osgb.setGridCoordinates(this.props.value.coordinates[0], this.props.value.coordinates[1]);
            //wgs84ref = this.osgb.getWGS84();
            //console.log("Lat=" + wgs84ref.latitude + ", Lng=" + wgs84ref.longitude);
            //if (this.osgb.parseGridRef(this.props.value)) {
            //    wgs84ref = this.osgb.getWGS84();
            //} else {
            //    //invalid = true;
            //}
        } catch (e) {
            invalid = true;
        }
        //"max-width: 100% !important; max-height: 100% !important;"
        let jobmarkers = this.state.visible ? this.renderJobs() : '';
        let wardenareas = this.state.visible ? this.renderWardenAreas() : '';
        return (
            <Modal isOpen={this.state.visible} size={"lg"} style={{ maxWidth: "99%", maxHeight: "100%" }} onHide={this.onHide}>
                <ModalHeader>Job Map&nbsp; &nbsp; <Button size={ "sm" } onClick={this.onHide}>Close</Button></ModalHeader>
                <ModalBody style={{ height:"calc(100vh - 120px)"}}>
                    <div style={{ width: "100% !important", height: "100%", marginBottom: "16px" }}>
                        {/*<PathMap onClick={this.onClickHandler} selectedLocation={wgs84ref ? { lat: wgs84ref.latitude, lng: wgs84ref.longitude } : null} isStatic={this.props.isStatic} selectZoom={1}>*/}
                        {/*</PathMap>*/}
                        <PathMap onClick={this.onClickHandler} selectedLocation={this.props.value && this.props.value.coordinates ? { lat: this.props.value.coordinates[1], lng: this.props.value.coordinates[0] } : null} isStatic={true} selectZoom={1} onAfterRender={this.onAfterRender}>
                            {wardenareas}

                            {jobmarkers}
                        </PathMap>
                    </div>
                </ModalBody>
            </Modal>
            
        );


    }
}
export default withTranslation('en')(JobMap);