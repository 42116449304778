import React, { Component } from 'react';
import {
    Card, CardBody, CardText, CardHeader,
    CardTitle, CustomInput, Col, Row, FormGroup, Form,
    Label, Input, Button, Badge, InputGroup, FormText,
    Modal, ModalFooter, ModalHeader, ModalBody
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import rowJobService from './RowJobService';

class RowJobCsvPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isopen: false,
            fieldlist: [],
            selectedfields:[]
        }
        this.loadEmptyJob();
    }
    async loadEmptyJob() {
        try {
            let data = await rowJobService.newjob();
            let fields = Object.keys(data);
            fields.push("wardenHours");
            fields.push("wardenCost");
            fields.push("contractorHours");
            fields.push("contractorCost");
            fields.push("materialCost");
            fields.push("volunteerCost");
            fields.push("totalCost");
            fields.push("bbnpaMaterialCost");
            fields.push("grantMaterialCost");
            fields.push("lengthKilometers");
            this.setState({ fieldlist: fields });
        } catch (ex) { }
    }
    async downloadCsv() {
        await rowJobService.getCsv(this.props.sortOrder, this.props.searchString, this.props.filters, this.state.selectedfields);
    }
   


    handleClickCancel = () => {
        this.setState({ isopen: false });
        if (this.props.onCancel) {

        }
    }
    onClickShowExportCsv = () => {
        this.setState({ isopen: true });
    }
    handleDownload = () => {
        this.downloadCsv();
    }
    fieldsChange = (e) => {
        var options = e.target.options;
        var value = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.setState({ selectedfields: value });
    }

    render() {
        return (<span>
            <Button size={"sm"} onClick={this.onClickShowExportCsv}>Export CSV</Button>
            <Modal isOpen={this.state.isopen} size={"xl"}>
                <ModalHeader>
                    CSV Export
                </ModalHeader>
                <ModalBody>
                    <Input style={{ height: "calc(100vh - 200px)" }} type="select" name="selectMulti" id="exampleSelectMulti" multiple onChange={this.fieldsChange}>
                        {this.state.fieldlist.map((field, index) => (
                            <option>{field }</option>
                            ))} 
                    </Input>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.handleDownload}>Download</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </span>
        );
    }
}
export default RowJobCsvPopup;