import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';
import { AsyncPaginate } from 'react-select-async-paginate';
import rowPersonService from '../rowpeople/RowPersonService';
import rowWardenAreaService from './RowWardenAreaService';
import WardenAreaMap from '../pathmap/WardenAreaMap';
//import FilterManager from '../filtermanager/FilterManager';
export class RowWardenAreaList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            page: 1,
            pageSize: 20,
            maxPages: 15,
            sortOrder: "",
            searchString: "",
            loading: true,
            filters: [],
            showpopup: false,
            selectedentity: null,
            hoverarea:null
        };
    }

    componentDidMount() {
        this.populateData();
    }
    async populateData() {
        try {
            const wardenAreas = await rowWardenAreaService.list();
            this.setState({ entities: wardenAreas.result });
        } catch (ex) { }
    }
    selectArea = (id) => {
        this.setState({
            selectedentity: this.state.entities.find(x => x.id == id)
        });
    }
    mouseOverArea = (id) => {
        this.setState({ hoverarea: this.state.entities.find(o => o.id == id) });
    }
    mouseOut = () => {
        this.setState({ hoverarea: null});
    }
    handleWardenSelectChange(e) {
        this.state.selectedentity["warden"] = e.obj;
        this.setState({ selectedentity: this.state.selectedentity });
    }
    updatearea = () => {
        (async () => {
            await rowWardenAreaService.update(this.state.selectedentity.id, this.state.selectedentity);
            this.populateData();
            this.setState({ selectedentity: null });
            //history.push('/rowmaterials');
            //history.goBack();
        })()
    }
    render() {
        return (
            <div>
                <h1>Warden Areas</h1>
                <div>{this.state.hoverarea ? 
                    (
                        <span>{this.state.hoverarea.warden.firstname + ' ' + this.state.hoverarea.warden.surname}</span>
                    ) : 'hover over area to show warden'}
                </div>
                <div></div>
                <WardenAreaMap
                    entities={this.state.entities}
                    onClick={this.selectArea}
                    onMouseOver={this.mouseOverArea}
                    onMouseOut={this.mouseOut }
                />
                {
                    this.state.selectedentity ?
                        (<div><AsyncPaginate
                            value={this.state.selectedentity ? { label: this.state.selectedentity.warden.firstname + ' ' + this.state.selectedentity.warden.surname, value: this.state.selectedentity.warden.id } : {}}
                            loadOptions={
                                async (search, loadedWardenOptions, { page }) => {
                                    const responseJSON = await rowPersonService.wardenselectlist(search, page, 0);
                                    return {
                                        options: responseJSON.result,
                                        hasMore: responseJSON.hasmore,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            onChange={this.handleWardenSelectChange.bind(this)}
                            additional={
                                { page: 1, }
                            }
                        /><Button onClick={ this.updatearea }>Save</Button></div>) : (<div></div>)
                }
            </div>
        );
    }
}