import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { RowJobList } from './RowJobList';
//import { RowJobAdd } from './RowJobAdd';
//import { RowJobDelete } from './RowJobDelete';
import { RowJobEdit } from './RowJobEdit';

export function RowJobRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			{/*<AuthorizeRoute path={`${match.path}/add`} component={RowJobAdd} />*/}
			{/*<Route path={`${match.path}/delete/:id`} component={RowJobDelete} />*/}
			<AuthorizeRoute path={`${match.path}/ongoing/:ongoingwardenid`} component={RowJobList} />
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={RowJobEdit} />
			<AuthorizeRoute path={`${match.path}/edit`} component={RowJobEdit} />
			<AuthorizeRoute path={`${match.path}`} component={RowJobList} />
		</Switch>
	);
}