import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { BridgeInspectionList } from './BridgeInspectionList';
import { BridgeInspectionEdit } from './BridgeInspectionEdit';

export function BridgeInspectionRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			{/*<AuthorizeRoute path={`${match.path}/calendar`} component={BridgeCalendar} />*/}
			{/*<AuthorizeRoute path={`${match.path}/add`} component={BridgeAdd} />*/}
			{/*<Route path={`${match.path}/delete/:id`} component={BridgeDelete} />*/}
			
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={BridgeInspectionEdit} />
			{/*<AuthorizeRoute path={`${match.path}/edit`} component={BridgeInspectionEdit} />*/}
			<AuthorizeRoute path={`${match.path}/add/:bridgeid`} component={BridgeInspectionEdit} />
			<AuthorizeRoute path={`${match.path}/add`} component={BridgeInspectionEdit} />
			<AuthorizeRoute path={`${match.path}`} component={BridgeInspectionList} />
		</Switch>
	);
}