import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { RowGrantList } from './RowGrantList';
//import { RowGrantAdd } from './RowGrantAdd';
//import { RowGrantDelete } from './RowGrantDelete';
import { RowGrantEdit } from './RowGrantEdit';

export function RowGrantRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			{/*<AuthorizeRoute path={`${match.path}/calendar`} component={RowGrantCalendar} />*/}
			{/*<AuthorizeRoute path={`${match.path}/add`} component={RowGrantAdd} />*/}
			{/*<Route path={`${match.path}/delete/:id`} component={RowGrantDelete} />*/}
			
			<AuthorizeRoute path={`${match.path}/edit/:id`} component={RowGrantEdit} />
			<AuthorizeRoute path={`${match.path}/edit`} component={RowGrantEdit} />
			<AuthorizeRoute path={`${match.path}`} component={RowGrantList} />
		</Switch>
	);
}