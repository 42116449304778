import React, { Component } from 'react';
import { withRouter } from 'react-router';
import bridgeService from './BridgeService';
import rowPersonService from '../rowpeople/RowPersonService';
import { Modal, ModalBody, ModalHeader, ModalFooter, Card, CardBody, CardText, CardHeader, CardTitle, CustomInput, Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { SelectFetch } from 'react-select-fetch';
import { AsyncPaginate } from 'react-select-async-paginate';
import userService from '../users/UserService';
import authService from '../api-authorization/AuthorizeService';
import GridrefPointInputPopupSelector from '../pathmap/GridrefPointPopupSelector';
import { wgs84, osgb } from "../osgb";

class BridgePopup extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            modal: false,
            bridge: null,
            id:null
        };
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value && nextProps.value.id !== this.state.id) {
            this.setState({ bridge: nextProps.value, id: nextProps.value.id, modal:true});
        } else {
            
        }
    }
    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }
    fixDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])).toLocaleDateString() : "";
    }
    toDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])) : null;
    }
    getLastInspection(e) {
        var lastinspection = this.toDate(e.lastInspected);
        for (var inspection of e.inspections) {
            let d = this.toDate(inspection.inspectionDate);
            if (lastinspection === null || d > lastinspection) {
                lastinspection = d;
            }
        }
        return lastinspection;
    }
    handleAddInspectionClick = () => {
        if (this.props.onAddInspection) {
            this.props.onAddInspection(this.state.bridge);
        }
    }
    handleLastInspectedChange = (e) => {
        this.state.bridge["lastInspected"] = e.target.value;
        this.setState({ bridge: this.state.bridge });
    }
    handleQueryChange = (e) => {
        this.state.bridge["queries"] = e.target.value;
        this.setState({ bridge: this.state.bridge });
    }
    saveChanges = () => {
        (async () => {
            await bridgeService.update(this.state.bridge.id, this.state.bridge);
        })();
    }
    fixDate(date) {
        return date != null && date.length > 0 ? date.split('T')[0] : "";
    }
    closePopup = () => {
        this.setState({ bridge: null,id:null });
        this.toggle();
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
    render() {
        let wgs84ref = null;
        let invalid = false;
        try {
            //this.osgb.setGridCoordinates(this.props.value.coordinates[0], this.props.value.coordinates[1]);
            //wgs84ref = this.osgb.getWGS84();
            //console.log("Lat=" + wgs84ref.latitude + ", Lng=" + wgs84ref.longitude);
            //if (this.osgb.parseGridRef(this.props.value)) {
            //    wgs84ref = this.osgb.getWGS84();
            //} else {
            //    //invalid = true;
            //}
        } catch (e) {
            invalid = true;
        }
        console.log(this.state.bridge);
        return (
            <Modal size="xl" isOpen={this.state.modal} toggle={this.toggle} >
                <ModalHeader toggle={this.toggle}>Bridge Details</ModalHeader>
                <ModalBody>
                    {this.state.bridge ? (
                        <GridrefPointInputPopupSelector value={this.state.bridge.location} readOnly={true} isStatic={ true} />
                        ) : ''}
                   
                    <Row>
                        <Col>Name</Col>
                        <Col>{this.state.bridge ? this.state.bridge.name : ''}</Col>
                    </Row>
                    <Row>
                        <Col>Code</Col>
                        <Col>{this.state.bridge ? this.state.bridge.code: ''}</Col>
                    </Row>
                    <Row>
                        <Col>Comments</Col>
                        <Col><Input type="textarea" name="queries" onChange={ this.handleQueryChange } value={this.state.bridge ? this.state.bridge.queries : ''} /></Col>
                    </Row>
                    <Row>
                        <Col>Last Inspected</Col>
                        <Col><Input type="date" name="lastinspected" onChange={ this.handleLastInspectedChange} value={this.state.bridge ? this.fixDate(this.state.bridge.lastInspected) : ''} /> </Col>
                        {/*{this.state.bridge && this.getLastInspection(this.state.bridge) != null ? this.getLastInspection(this.state.bridge).toLocaleDateString() : ''}*/}
                    </Row>
                    <Row>
                        <Col>Build Date</Col>
                        <Col>{this.state.bridge && this.state.bridge.builtDate != null ? this.toDate(this.state.bridge.builtDate).toLocaleDateString() : '' }</Col>
                    </Row>
                    
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={ this.saveChanges }>Save</Button>{' '}
                    <Button color="primary" onClick={this.handleAddInspectionClick}>Add inspection</Button>{' '}
                    <Button color="secondary" onClick={this.closePopup}>Close</Button>
                </ModalFooter>
            </Modal>
        );


    }
}
export default withTranslation('en')(BridgePopup);