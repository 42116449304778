import React, { Component } from 'react';
import {
    Card, CardBody, CardText, CardHeader,
    CardTitle, CustomInput, Col, Row, FormGroup, Form,
    Label, Input, Button, Badge, InputGroup, FormText,
    Modal, ModalFooter, ModalHeader, ModalBody
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import rowPersonService from './RowPersonService';

class RowPersonPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: props.entity ? props.entity : {
                id: this.uuidv4(),
                firstname: "",
                surname: "",
                email: "",
                public: true
            },
            loading: false,
            locked: false,
            isnew: props.entity ? false : true,
            isopen: false
        }
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.entity) {
            this.setState({ entity: nextProps.entity, isnew: false });
        } else {
            this.setState({
                entity: {
                    id: this.uuidv4(),
                    firstname: "",
                    surname: "",
                    email: "",
                    hourlyRate: 0,
                    public: true
                }, isnew: true});
        }
        if (nextProps.visible) {
            this.setState({ isopen: true });
        } else {
            this.setState({ isopen: false});
        }
    }
    handleIsPublic = (e) => {
        let entity = this.state.entity;
        entity.public = !entity.public;
        this.setState({ entity: entity });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;

        this.setState({ entity: this.state.entity });
    }
    handleClickCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }
    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = this.state.entity;

        if (this.state.isnew) {
            (async () => {
                await rowPersonService.add(postvalues);
                if (this.props.onAdd) {
                    this.props.onAdd(postvalues)
                }
                //history.push('/rowmaterials');
                //history.goBack();
            })();
        } else {
            (async () => {
                await rowPersonService.update(postvalues.id, postvalues);
                if (this.props.onUpdate) {
                    this.props.onUpdate(postvalues)
                }
                //history.push('/rowmaterials');
                //history.goBack();
            })();
        }

    }
    renderForm(entity) {
        return (

            <Form onSubmit={this.handleValidSubmit}>
                <Card>
                    <CardBody>
                        <FormGroup row>
                            <Label for="firstname" sm={2}>Firstname</Label>
                            <Col sm={10}>
                                <Input type="text" name="firstname" value={entity.firstname} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="surname" sm={2}>Surname</Label>
                            <Col sm={10}>
                                <Input type="text" name="surname" value={entity.surname} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="email" sm={2}>Email</Label>
                            <Col sm={10}>
                                <Input type="email" name="email" value={entity.email} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="phone" sm={2}>Phone</Label>
                            <Col sm={10}>
                                <Input type="text" name="phone" value={entity.phone} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="hourlyRate" sm={2}>Hourly Rate</Label>
                            <Col sm={10}>
                                <Input type="number" name="hourlyRate" value={entity.hourlyRate} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="public" sm={2}></Label>
                            <Col sm={10}>
                                <CustomInput type="switch" id="public" name="public" checked={entity.public} label="Is member of public" onChange={this.handleIsPublic} />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>

        )
    }
    render() {
        let contents = this.state.loading
            ? ""
            : this.renderForm(this.state.entity);

        return (
            <Modal isOpen={this.state.isopen} size={"xl" }>
                <ModalHeader>
                    {this.state.isnew ? "Add new person" : "Edit person"}
                </ModalHeader>
                <ModalBody>
                    {contents}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.handleValidSubmit}>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
export default RowPersonPopup;