import React, { Component } from "react";
import ReactDOM from 'react-dom';
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import pathService from '../paths/PathService';
import { Button, FormGroup, Input, Label } from "reactstrap";
import rowJobService from '../rowjobs/RowJobService';
//import rowStatusService from '../rowstatuses/RowStatusService';
import rowWardenAreaService from '../rowwardenareas/RowWardenAreaService';
import {
    Map,
    WMSTileLayer,
    LayersControl,
    Polyline,
    Popup,
    Marker,
    Tooltip,
    AutoOpenPopupMarker,
    withLeaflet,
    ZoomControl,
    Polygon
} from "react-leaflet";
const { BaseLayer, Overlay } = LayersControl;
//import L, { Tooltip } from "leaflet";

//delete L.Icon.Default.prototype._getIconUrl;

//L.Icon.Default.mergeOptions({
//    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//    iconUrl: require('leaflet/dist/images/marker-icon.png'),
//    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
//});

class WardenAreaMap extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            wardenareas: [],
            loading: true
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.entities != this.state.wardenareas) {
            this.setState({ wardenareas: nextProps.entities });
        }
    }
    //async populateData() {
    //    try {
    //        const wardenAreas = await rowWardenAreaService.list();
    //        this.setState({ wardenareas: wardenAreas.result });
    //    } catch (ex) { }
    //}
    onNextFrame() {
        var _this = this;
        window.requestAnimationFrame(function () {
            var domnode = document.querySelector(".ab75a991-a956-42a5-9704-29c031a32861");//_this.getDOMNode();
            if (domnode !== undefined) {
                console.log(domnode);
                // set your attributes here
                /*jwplayer(domnode).setup({
                    "file": "http://example.com/myVideo.mp4",
                    "image": "http://example.com/myImage.png",
                    "height": 360,
                    "width": 640
                });*/
            }
        });
    }
    componentDidMount() { }
    componentDidUpdate() { }
    renderWardenAreas() {
        // [wardens].coordinates[].polygons[].coordinates[].coordinate[]
        let wardenareas = [];
        let ii = 0;
        //[wardens]
        for (var wardenarea of this.state.wardenareas) {
            if (ii++ < 10) {
                //console.log(wardenarea.warden.firstname + ' ' + wardenarea.warden.surname);
                let multipoly = [];
                // wardens[].coordinates[]
                for (var wardenpoly of wardenarea.area.coordinates) {
                    //wardenarea.area.coordinates
                    // wardens[].coordinates[].polygons[]
                    let poly = [];
                    for (var coord of wardenpoly[0]) {
                        //wardenarea.area.coordinates.[poly's]
                        this.osgb.eastings = coord[0];
                        this.osgb.northings = coord[1];
                        let wgs84ref = this.osgb.getWGS84();
                        if (wgs84ref.latitude) {
                            poly.push([wgs84ref.latitude, wgs84ref.longitude]);
                        } else {
                            //console.log("'" + coord[0] + "' - '" + coord[1] + "'");
                        }
                    };
                    multipoly.push(poly);
                };
                wardenareas.push(
                    <Polygon
                        className={'wapath' + wardenarea.id}
                        positions={multipoly}
                        onClick={this.onWardenAreaClick}
                        onmouseover={this.mouseover}
                        onmouseout={this.mouseout }
                    />
                );
            }
        };
        return wardenareas;
    }
    onWardenAreaClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e.target.options.className.replace("wapath", ""));
        }
    }
    //onEnterArea = (e) => {
    //    console.log(e);
    //    if (this.props.onEnter) {
    //        this.props.onEnter(e.target.options.className.replace("wapath", ""));
    //    }
    //}
    //onLeaveArea = (e) => {
    //    if (this.props.onLeave) {
    //        this.props.onLeave();
    //    }
    //}
    mouseover = (e) => {
        if (this.props.onMouseOver) {
            this.props.onMouseOver(e.target.options.className.replace("wapath", ""));
        }
    }
    mouseout = (e) => {
        if (this.props.onMouseOut) {
            this.props.onMouseOut();
        }
    }
    onAfterRender = () => {
        let colors = [
            'red',
            'blue',
            'green',
            'cyan',
            'orange',
            'CornflowerBlue',
            'violet',
            'purple',
            '#556b2f',
            '#ffdb58',
            '#e2725b',
            '#cc5500',
            '#8b4513',
            '#fcv6d3',
            '#9e96fb',
            '#01f4dc',
            '#ffff81',
            '#6890ab',
            '#ff2f8e',
            '#66df48',
            '#6a77dd',
            '#9803ce',
            '#ffd600'
        ];
        let colorindex = 0;
        for (var wardenarea of this.state.wardenareas) {
            let id = ".wapath" + wardenarea.id.trim();
            let path = document.querySelector(id); // ab75a991-a956-42a5-9704-29c031a32861
            if (path) {
                path.setAttribute("stroke", colors[colorindex]);
                path.setAttribute("fill", colors[colorindex]);
                //path.onClick = this.onWardenAreaClick;
            }
            colorindex++;
        }
    }
    render() {
        let wardenareas = this.renderWardenAreas();
        return (
            <div style={{ width: "100%", height: "500px", marginBottom: "16px" }}>
                <PathMap
                    onClick={this.onClickHandler}
                    selectedLocation={this.props.value && this.props.value.coordinates ? { lat: this.props.value.coordinates[1], lng: this.props.value.coordinates[0] } : null}
                    isStatic={true}
                    selectZoom={1}
                    onAfterRender={this.onAfterRender}>
                    {wardenareas}
                </PathMap>
            </div>
        );


    }
}
export default withTranslation('en')(WardenAreaMap);