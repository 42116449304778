import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import rowPersonService from './RowPersonService';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';
import RowPersonPopup from './RowPersonPopup';
//import FilterManager from '../filtermanager/FilterManager';
export class RowPersonList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            page: 1,
            pageSize: 20,
            maxPages: 15,
            sortOrder: "surname",
            searchString: "",
            loading: true,
            filters: [],
            showpopup: false,
            selectedentity: null
        };
    }

    componentDidMount() {
        this.populateData();
    }

    handlePageChange = (page) => {
        this.setState({ page: page }, () => this.populateData());
    }

    handleHeaderClick = (event, header) => {
        event.preventDefault();

        let newSortOrder = this.state.sortOrder;

        switch (header) {
            case 'firstname': {
                newSortOrder = this.state.sortOrder === 'firstname' ? 'firstname_desc' : 'firstname';
                break;
            }
            case 'surname': {
                newSortOrder = this.state.sortOrder === 'surname' ? 'surname_desc' : 'surname';
                break;
            }
            case 'email': {
                newSortOrder = this.state.sortOrder === 'email' ? 'email_desc' : 'email';
                break;
            }
            case 'public': {
                newSortOrder = this.state.sortOrder === 'public' ? 'public_desc' : 'public';
                break;
            }
        }

        this.setState({ page: 1, sortOrder: newSortOrder }, () => this.populateData());
        return false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleSearchFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ page: 1 }, () => this.populateData());
        //this.populateData();
    }

    handleSearchFormReset = (event) => {
        event.preventDefault();
        this.setState({ page: 1, searchString: "" }, () => this.populateData());
    }
    selectEntity = (entity) => {
        this.setState({ selectedentity: entity, showpopup: true });
    }
    handleSavePerson = (o) => {
        this.setState({
            showpopup: false,
            selectedentity: null
        });
        this.populateData();
    }
    addPersonClick = () => {
        this.setState({
            selectedentity: null
        });
        this.setState({
            showpopup: true
        });
    }
    handleAddPerson = (o) => {
        this.setState({
            showpopup: false,
            selectedentity: null
        });
        this.populateData();
    }
    handleSavePerson = (o) => {
        this.setState({
            showpopup: false,
            selectedentity: null
        });
        this.populateData();
    }
    handlePopupCancel = (o) => {
        this.setState({
            showpopup: false,
            selectedentity: null
        });
    }
    renderTable() {
        const { entities, total, sortOrder } = this.state;

        return (
            <div>
                <RowPersonPopup entity={this.state.selectedentity} onAdd={this.handleAddPerson} onCancel={this.handlePopupCancel} onUpdate={this.handleSavePerson} visible={this.state.showpopup} />
                <table className='table table-striped' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'firstname')} >
                                    Firstname&nbsp;
                                    {sortOrder == 'firstname' && <FaSortAlphaDown />}
                                    {sortOrder == 'firstname_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'surname')} >
                                    Surname&nbsp;
                                    {sortOrder == 'surname' && <FaSortAlphaDown />}
                                    {sortOrder == 'surname_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'email')} >
                                    Email&nbsp;
                                    {sortOrder == 'email' && <FaSortAlphaDown />}
                                    {sortOrder == 'email_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'public')} >
                                    Public&nbsp;
                                    {sortOrder == 'public' && <FaSortAlphaDown />}
                                    {sortOrder == 'public_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {entities.map(entity =>
                            <tr key={entity.id} style={{ cursor: 'pointer' }} onClick={() => this.selectEntity(entity)}>
                                <td>{entity.firstname}</td>
                                <td>{entity.surname}</td>
                                <td>{entity.email}</td>
                                <td>{entity.public ? "Yes" : "No"}</td>
                                <td><Link to={'/row/delete/' + entity.id}><FaTrash /></Link></td>
                                <td><Link to={'/row/edit/' + entity.id}><FaEdit /></Link></td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Pager totalItems={total} page={this.state.page} pageSize={this.state.pageSize} maxPages={this.state.maxPages} handlePageChange={this.handlePageChange} />
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])).toLocaleDateString() : "";
    }
    
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTable();

        return (
            <div>
                <h1 id="tableLabel">People</h1>
                {/*<FilterManager onChange={this.filterChange} type={"rowmaterialfilters"} />*/}
                <div>&nbsp;</div>
                <Form inline onSubmit={this.handleSearchFormSubmit}>
                    <Button size="sm" onClick={this.addPersonClick }>Add person</Button>&nbsp;
                    <FormGroup>
                        <Input type="text" name="searchString" value={this.state.searchString} onChange={this.handleInputChange} placeholder="search string" />
                    </FormGroup>&nbsp;
                    <Button size="sm">Search</Button>&nbsp;
                    <Button size="sm" onClick={this.handleSearchFormReset}>Reset</Button>&nbsp;
                </Form><br />
                {contents}
            </div>
        );
    }
    filterChange = (filters) => {
        this.setState({ filters: filters });
        this.setState({ page: 1 }, () => this.populateData());
    }
    async populateData() {
        try {

            const data = await rowPersonService.getData(
                this.state.page,
                this.state.pageSize,
                this.state.sortOrder,
                this.state.searchString,
                this.state.filters
            );
            
            this.setState({ total: data.total, entities: data.entities, loading: false });
        }
        catch (error) {
            // Handle error
        }
    }
}