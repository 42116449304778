import React, { Component } from 'react';
import {
    Card, CardBody, CardText, CardHeader,
    CardTitle, CustomInput, Col, Row, FormGroup, Form,
    Label, Input, Button, Badge, InputGroup, FormText,
    Modal, ModalFooter, ModalHeader, ModalBody
} from 'reactstrap';
import authService from '../api-authorization/AuthorizeService';
import rowMaterialService from './RowMaterialService';

class RowAddMaterialPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: {
                id: this.uuidv4(),
                name: '',
                cost: '',
                archived: false
            },
            loading: false,
            isopen: false
        }
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            entity: {
                id: this.uuidv4(),
                name: '',
                cost: '',
                archived: false
            }, isnew: true
        });
        this.setState({ isopen: false });
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;

        this.setState({ entity: this.state.entity });
    }
    handleOpen = () => {
        this.setState({ isopen: true });
    }
    handleClickCancel = () => {
        this.setState({ isopen: false });
        if (this.props.onCancel) {
            
            this.props.onCancel();
        }
    }
    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = this.state.entity;
        let returnobj = {
            id: this.state.entity.id,
            name: this.state.entity.name,
            cost: this.state.entity.cost,
            archived: false
        };
        (async () => {
            await rowMaterialService.add(postvalues);
            if (this.props.onAdd) {
                this.props.onAdd(returnobj);
            }
        })();

        this.setState({
            entity: {
                id: this.uuidv4(),
                name: '',
                cost: '',
                archived: false
            },
            isopen: false
        });
    }
    renderForm(entity) {
        return (

            <Form onSubmit={this.handleValidSubmit}>
                <Card>
                    <CardBody>
                        <FormGroup row>
                            <Label for="name" sm={2}>Name</Label>
                            <Col sm={10}>
                                <Input type="text" name="name" value={entity.name} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="cost" sm={2}>Cost</Label>
                            <Col sm={10}>
                                <Input type="number" name="cost" value={entity.cost} onChange={this.handleInputChange} />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Card>
            </Form>

        )
    }
    render() {
        let contents = this.renderForm(this.state.entity);

        return (
            <span>
                <Button onClick={ this.handleOpen}>Add Unlisted Material</Button>
                <Modal isOpen={this.state.isopen} size={"xl"}>
                    <ModalHeader>
                        Add new material
                    </ModalHeader>
                    <ModalBody>
                        {contents}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.handleValidSubmit}>Add</Button>&nbsp;
                        <Button onClick={this.handleClickCancel}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </span>
        );
    }
}
export default RowAddMaterialPopup;