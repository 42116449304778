import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { BridgeList } from './BridgeList';
import { BridgeEdit } from './BridgeEdit';

export function BridgeRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			{/*<AuthorizeRoute path={`${match.path}/calendar`} component={BridgeCalendar} />*/}
			{/*<AuthorizeRoute path={`${match.path}/add`} component={BridgeAdd} />*/}
			{/*<Route path={`${match.path}/delete/:id`} component={BridgeDelete} />*/}
			
			{/*<AuthorizeRoute path={`${match.path}/edit/:id`} component={BridgeEdit} />*/}
			{/*<AuthorizeRoute path={`${match.path}/edit`} component={BridgeEdit} />*/}
			<AuthorizeRoute path={`${match.path}/due/:duebywardenid`} component={BridgeList} />
			<AuthorizeRoute path={`${match.path}`} component={BridgeList} />
		</Switch>
	);
}