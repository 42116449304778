import React, { Component } from 'react';
import { withRouter } from 'react-router';
import bridgeInspectionService from './BridgeInspectionService';
import bridgeService from '../bridges/BridgeService';

import rowPersonService from '../rowpeople/RowPersonService';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {
    Card, CardBody, CardText, CardHeader, CardTitle,
    CustomInput, Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText,
    UncontrolledCarousel
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import { SelectFetch } from 'react-select-fetch';
import { AsyncPaginate } from 'react-select-async-paginate';
import userService from '../users/UserService';
import authService from '../api-authorization/AuthorizeService';
import GridrefPointInputPopupSelector from '../pathmap/GridrefPointPopupSelector';

import { wgs84, osgb } from "../osgb";

const bridgeInspectionItemTypes = [
    "Foundations",
    "River bed",
    "Bank",
    "Piers",
    "End seat",
    "Wingwalls",
    "Abutments",
    "Damp proof membrane",
    "HD Brackets",
    "HD Bolts",
    "Main Beams",
    "Cross / Traverse",
    "Beams",
    "Tie bar",
    "Concrete slab",
    "Timber decking",
    "Handrail uprights",
    "Handrails",
    "Mid rails",
    "Preservative",
    "Paintwork",
    "Retaining wall",
    "Revetment",
    "Waymarked"
]


class BridgeInspectionEditPlain extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            entity: null,
            loading: true,
            gridref: '',
            locked: false,
            isnew: false,
            warden: null,
            otheritemname: '',
            photoitems: [],
            photos: []
        };

        const { match } = this.props;
        this.bridgeid = match.params.bridgeid;
        this.id = match.params.id;
    }




    handleBridgeSelectChange(e) {
        this.state.entity["bridge"] = e.obj;
        this.setState({ entity: this.state.entity });
    }
    handleWardenSelectChange(e) {
        this.state.entity["warden"] = e.obj;
        this.setState({ entity: this.state.entity });
    }
    async getSelectOptions() {

    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.authenticationChanged());
        this.populateAuthenticationState();

    }
    async authenticationChanged() {
        this.setState({ ready: false, authenticated: false });
        await this.populateAuthenticationState();
    }
    async populateAuthenticationState() {
        const authenticated = await authService.isAuthenticated();
        const user = await authService.getUser();
        this.setState({ ready: true, isAuthenticated: authenticated, user, role: user && user.role, isAdmin: user.role.includes("Administrator") });
        this.userStateReturned();
    }
    userStateReturned() {
        this.retrieveFormData();
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.entity[name] = value;
        if (!this.state.entity.priorityOverridden && this.state.entity.priorityLevel) {
            this.state.entity.dueDate = this.fixDate(this.addMonths(new Date(this.state.entity.reportedDate), this.state.entity.priorityLevel.months).toISOString());
        }
        //console.log("name=" + name + ",val=" + value + ",ov=" + this.state.entity[name] + ", tt=" + target.type);
        this.setState({ entity: this.state.entity });
        if (name === "prow") {
            this.getPriorityForProw(value);
        }
    }

    handleValidSubmit = (event, values) => {
        const { history } = this.props;
        let postvalues = this.state.entity;
        let isvalid = true;
        if (postvalues.inspectionDate === "") {
            postvalues.inspectionDate = null;
        }
        if (postvalues.dueDate === "") {
            postvalues.dueDate = null;
        }
        if (postvalues.warden == null || postvalues.bridge == null || postvalues.inspectionDate == null || postvalues.inspectionDate == "") {
            isvalid = false;
        }
        if (isvalid) {
            if (this.state.isnew) {
                (async () => {
                    let ret = await bridgeInspectionService.add(postvalues);
                    if (ret.rowJobId) {
                        history.push('/rowjobs/edit/' + ret.rowJobId);
                    } else {
                        history.goBack();
                    }
                    
                    console.log(ret);
                    //
                })();
            } else {
                (async () => {
                    await bridgeInspectionService.update(this.id, postvalues);
                    //history.push('/rowjobs');
                    history.goBack();
                })();
            }
        }
    }

    handleFileChange = (e) => {
        //this.setState({ file: e.target.files[0] });
        if (e.target.value) {
            const formdata = new FormData();
            formdata.append('file', e.target.files[0]);
            formdata.append('id', this.state.entity.id);
            (async () => {
                await fetch("bridgeinspection/postimage", {
                    method: "post",
                    /*headers: { 'Content-Type': 'multipart/form-data' },*/
                    body: formdata
                });
                let photos = await bridgeInspectionService.photolist(this.state.entity.id);
                this.setPhotoItems(photos);
            })();
            e.target.value = null;
        }
    }
    setPhotoItems(photos) {
        let items = [];
        let index = 1;
        for (var item of photos) {
            items.push({
                altText: "",
                caption: "",
                key: index++,
                src: "/bridgeinspection/photo?photo=" + item
            });
        }
        if (index === 1) {
            items.push({
                altText: "",
                caption: "",
                key: index++,
                src: "/bridgeinspection/photo?photo=none" 
            });
        }
        this.setState({ photoitems: items,photos:photos });
    }

    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    handleItemChange = (e) => {
        if (this.state.entity.items.find(o => o.name === e.target.name)) {
            let newitems = [];
            for (var item of this.state.entity.items) {
                if (item.name !== e.target.name) {
                    newitems.push(item);
                }
            }
            this.state.entity["items"] = newitems;
            this.setState({ entity: this.state.entity });
        } else {
            this.state.entity.items.push({ id: this.uuidv4(), name: e.target.name })
            this.setState({ entity: this.state.entity });
        }
    }
    handleOtherItemChange = (e) => {
        this.setState({ otheritemname: e.target.value });
    }
    handleWorkRequiredChange = (e) => {
        this.state.entity["workRequired"] = e.target.checked;
        this.setState({ entity: this.state.entity });
    }
    handleAddOtherItem = () => {
        this.state.entity.items.push({ id: this.uuidv4(), name: this.state.otheritemname });
        this.setState({ entity: this.state.entity, otheritemname: '' });
    }
    renderInspectedItems(entity) {
        let defaultItems = bridgeInspectionItemTypes.sort((a, b) => { return a > b ? -1 : a < b ? 1 : 0 });
        let additionalItems = [];
        for (var item of entity.items) {
            if (!defaultItems.includes(item.name)) {
                additionalItems.push(item.name);
            }
        }
        let allItems = [];
        for (var item of defaultItems) {
            allItems.push(item);
        }
        for (var item of additionalItems) {
            allItems.push(item);
        }
        let ctls = [];
        for (var item of allItems) {
            if (entity.items.find(o => o.name === item)) {
                ctls.push(<CustomInput checked={true} type="switch" id={item} name={item} label={item} onChange={this.handleItemChange} />);
            }
            else {
                ctls.push(<CustomInput checked={false} type="switch" id={item} name={item} label={item} onChange={this.handleItemChange} />);
            }
        }
        return (
            <div>
                {ctls}
                <FormGroup row>
                    <Label for="inspectionDate" sm={2}>Other</Label>
                    <Col sm={6}>
                        <Input type="text" name="otheritem" value={this.state.otheritemname} onChange={this.handleOtherItemChange} readOnly={this.state.locked} />
                    </Col>
                    <Col sm={4}><Button onClick={this.handleAddOtherItem}>Add</Button></Col>
                </FormGroup>
            </div>
        )
    }
    renderForm(entity) {
        const { t, i18n } = this.props;
        const openInNewTab = url => {
            window.open(url, '_blank', 'noopener,noreferrer');
        };
        return (
            <Form onSubmit={this.handleValidSubmit}>
                <Card>
                    <CardBody>
                        <FormGroup row>
                            <Label for="bridge" sm={2}>Bridge</Label>
                            <Col sm={10}>
                                <AsyncPaginate
                                    value={entity.bridge ? { label: entity.bridge.code + ' ' + entity.bridge.name, value: entity.bridge.id } : {}}
                                    loadOptions={
                                        async (search, loadedAssignedToOptions, { page }) => {
                                            const responseJSON = await bridgeService.selectlist(search, page, 0);
                                            return {
                                                options: responseJSON.result,
                                                hasMore: responseJSON.hasmore,
                                                additional: {
                                                    page: page + 1,
                                                },
                                            };
                                        }}
                                    onChange={this.handleBridgeSelectChange.bind(this)}
                                    additional={
                                        { page: 1, }
                                    }
                                    readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="inspectionDate" sm={2}>Inspection Date</Label>
                            <Col sm={10}>
                                <Input type="date" name="inspectionDate" value={entity.inspectionDate} onChange={this.handleInputChange} readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="warden" sm={2}>Inspected By</Label>
                            <Col sm={10}>
                                <AsyncPaginate
                                    value={entity.warden ? { label: entity.warden.firstname + ' ' + entity.warden.surname, value: entity.warden.id } : {}}
                                    loadOptions={
                                        async (search, loadedAssignedToOptions, { page }) => {
                                            const responseJSON = await rowPersonService.wardenselectlist(search, page, 0);
                                            return {
                                                options: responseJSON.result,
                                                hasMore: responseJSON.hasmore,
                                                additional: {
                                                    page: page + 1,
                                                },
                                            };
                                        }}
                                    onChange={this.handleWardenSelectChange.bind(this)}
                                    additional={
                                        { page: 1, }
                                    }
                                    readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <div>Items Inspected</div>
                        {this.renderInspectedItems(entity)}
                        <FormGroup row>
                            <Label for="notes" sm={2}>Inspection Notes</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="notes" value={entity.notes} onChange={this.handleInputChange} readOnly={this.state.locked} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col sm={12}>
                                <CustomInput checked={entity.workRequired} type="switch" id="workRequired" name="workRequired" label="Work Required (new job will be created on save)" onChange={this.handleWorkRequiredChange} />
                            </Col>
                        </FormGroup>
                        {entity.rowJobId ?
                            <FormGroup row>
                                <Col sm={12}>
                                    <Button onClick={() => openInNewTab('/rowjobs/edit/' + entity.rowJobId)}>Open Job</Button>
                                </Col>
                            </FormGroup>
                            : ''}

                        {entity.workRequired ?
                            <FormGroup row>
                                <Label for="workDetails" sm={2}>Work Details</Label>
                                <Col sm={10}>
                                    <Input type="textarea" name="workDetails" value={entity.workDetails} onChange={this.handleInputChange} readOnly={this.state.locked} />
                                </Col>
                            </FormGroup>
                            : ''}
                        <UncontrolledCarousel items={this.state.photoitems} >
                        </UncontrolledCarousel>
                        <div>&nbsp;</div>
                        <Row>
                            <Col xs="4"><b>Add Photo </b>file will be uploaded immediately when selected</Col>
                            <Col xs="8"><Input type="file" accept="image/*" name="upload" onChange={(e) => this.handleFileChange(e)} /></Col>
                        </Row>
                    </CardBody>
                </Card>

                <FormGroup row>
                    <Label for="" sm={2}> </Label>
                    <Col sm={10}>
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Button onClick={this.handleValidSubmit}>Save</Button>&nbsp;
                    <Button onClick={this.handleClickCancel}>Cancel</Button>
                </FormGroup>
            </Form>

        );
    }
    addMonths(dateObj, num) {

        var currentMonth = dateObj.getMonth() + dateObj.getFullYear() * 12;
        dateObj.setMonth(dateObj.getMonth() + num);
        var diff = dateObj.getMonth() + dateObj.getFullYear() * 12 - currentMonth;

        // If don't get the right number, set date to 
        // last day of previous month
        if (diff != num) {
            dateObj.setDate(0);
        }

        return dateObj;
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderForm(this.state.entity);

        return (
            <div>
                <h1 id="tabelLabel">Bridge Inspection</h1>
                {contents}
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? date.split('T')[0] : "";
    }

    async retrieveFormData() {
        let isnew = false;
        let data = {}
        if (this.id) {
            data = await bridgeInspectionService.get(this.id);
            data.inspectionDate = this.fixDate(data.inspectionDate);
        }
        else {
            let bridge = null;
            if (this.bridgeid) {
                bridge = await bridgeService.get(this.bridgeid);
            }
            data = {
                id: this.uuidv4(),
                bridge: bridge,
                inspectionDate: (new Date()).toLocaleDateString(),
                items: [],
                workDetails: '',
                workRequired: false,
                rowJobId: null,
                notes: ''
            }
            isnew = true;
        }
        let photos = await bridgeInspectionService.photolist(data.id);
        this.setState({ entity: data, loading: false, isnew: isnew, photos: photos });
        this.setPhotoItems(photos);
        console.log(data);
    }
}

export const BridgeInspectionEdit = withTranslation()(withRouter(BridgeInspectionEditPlain));