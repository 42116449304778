import React, { Component } from "react";
import { wgs84, osgb } from "../osgb";
import PathMap from "./PathMap";
import { withTranslation } from 'react-i18next';
import pathService from '../paths/PathService';
import { Button, FormGroup, Input, Label } from "reactstrap";

class GridrefPointPopupSelector extends Component {
    constructor(props) {
        super(props);
        this.wgs84 = new wgs84();
        this.osgb = new osgb();
        this.state = {
            selectedfeature: null,
            selectedgridref: null,
            selectedlocation: null
        };
    }
    onClickHandler = (event, features) => {
        this.wgs84.setDegrees(event.latlng.lat, event.latlng.lng);
        //convert to OSGB
        let osgbref = this.wgs84.getOSGB();
        //this.osgb.parseGridRef(osgbref.getGridRef(6));
        // let wgs84ref = this.osgb.getWGS84();
        if (features.numberReturned > 0 && this.props.onChange) {
            //features.features[0].properties.row_code)
            //features.features[0].properties.description
            this.props.onChange({
                "easting": osgbref.eastings,
                "northing": osgbref.northings,
                "lng": event.latlng.lng,
                "lat": event.latlng.lat,
                "gridref": osgbref.getGridRef(6),
                "prow": features.features[0].properties.row_code,
                "rowname": features.features[0].properties.description
            });
        }
    }
    async getPathData(name) {
        try {
            const data = await pathService.getbyname(name);
            this.props.onChangePath(data.result);
        }
        catch (error) {
            // Handle error
        }
    }
    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.state.entity[name] = value;
        this.setState({ entity: this.state.entity });
    }
    render() {
        let wgs84ref = null;
        let invalid = false;
        try {
            //this.osgb.setGridCoordinates(this.props.value.coordinates[0], this.props.value.coordinates[1]);
            //wgs84ref = this.osgb.getWGS84();
            //console.log("Lat=" + wgs84ref.latitude + ", Lng=" + wgs84ref.longitude);
            //if (this.osgb.parseGridRef(this.props.value)) {
            //    wgs84ref = this.osgb.getWGS84();
            //} else {
            //    //invalid = true;
            //}
        } catch (e) {
            invalid = true;
        }
        if (invalid && this.props.hideInvalid) {
            return (<div></div>);
        }
        else {
            return (
                <div style={{ width: "100%", height: "400px",marginBottom:"16px" }}>
                    {/*<PathMap onClick={this.onClickHandler} selectedLocation={wgs84ref ? { lat: wgs84ref.latitude, lng: wgs84ref.longitude } : null} isStatic={this.props.isStatic} selectZoom={1}>*/}
                    {/*</PathMap>*/}
                    <PathMap onClick={this.onClickHandler} selectedLocation={this.props.value && this.props.value.coordinates ? { lat: this.props.value.coordinates[1], lng: this.props.value.coordinates[0] } : null} isStatic={this.props.isStatic} selectZoom={1}>
                    </PathMap>
                </div>
            );
        }

    }
}
export default withTranslation('en')(GridrefPointPopupSelector);