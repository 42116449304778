import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FaTools, FaCalendar, FaCalendarAlt, FaMapMarkerAlt, FaPencilAlt } from 'react-icons/fa';
import {
    Badge,
    Container, Row, Col,
    CardDeck, Card, CardImg, CardText, CardBody, CardFooter, CardHeader,
    CardTitle, CardSubtitle, Button, CardLink
} from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './api-authorization/AuthorizeService';
import rowJobService from './rowjobs/RowJobService';
import bridgeService from './bridges/BridgeService';
export class HomePlain extends Component {
    static displayName = HomePlain.name;
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: false,
            role: null,
            user: null,
            firstname: null,
        };
    }
    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }
    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }
    async populateState() {

        const ongoingjobsbywarden = await rowJobService.ongoingbywarden();
        const duebywarden = await bridgeService.duebywarden();

        const [isAuthenticated, user] = await Promise.all([
            authService.isAuthenticated(),
            authService.getUser(),
        ]);
        this.setState({
            isAuthenticated,
            role: user && user.role,
            user: user,
            firstname: user && user.firstname,
            ongoingjobsbywarden: ongoingjobsbywarden.sort((a, b) => { return b.count - a.count }),
            duebywarden: duebywarden.sort((a, b) => { return b.count - a.count }),
        });
    }
    render() {
        return (
            <div >

                <CardDeck>


                    {this.state.user && (this.state.user.role.includes("Administrator") || this.state.user.role.includes("Warden")) ? (
                        <Card>
                            <CardHeader><CardTitle tag="h4"><FaTools /> <Link to='/rowjobs' className="CardHeadLink">Areas</Link></CardTitle></CardHeader>
                            <CardBody>
                                <p>

                                </p>
                                <p>
                                    <Link className='CardBodyLink' to='/rowjobs'>Job list</Link>
                                </p>
                                <p>
                                    <Link className='CardBodyLink' to='/bridges'>Bridges</Link>
                                </p>
                                <p>
                                    <Link className='CardBodyLink' to='/bridgeinspections'>Bridge Inspections</Link>
                                </p>
                                <p>
                                    <Link className='CardBodyLink' to='/rowmaterials'>Materials</Link>
                                </p>
                                {this.state.user.role.includes("Administrator") ? (
                                    <p>
                                        <Link className='CardBodyLink' to='/rowpeople'>People</Link>
                                    </p>
                                ) : ''}
                                {this.state.user.role.includes("Administrator") ? (
                                    <p>
                                        <Link className='CardBodyLink' to='/rowgrants'>Grants</Link>
                                    </p>
                                ) : ''}
                                {this.state.user.role.includes("Administrator") ? (
                                    <p>
                                        <Link className='CardBodyLink' to='/rowwardenareas'>Warden Areas</Link>
                                    </p>
                                ) : ''}
                            </CardBody>
                        </Card>
                    ) : ''}
                    {this.state.user && (this.state.user.role.includes("Administrator") || this.state.user.role.includes("Warden")) ? (
                        <Card>
                            <CardHeader><CardTitle tag="h4"><FaTools /> Ongoing Jobs</CardTitle></CardHeader>
                            <CardBody>

                                {
                                    this.state.ongoingjobsbywarden.map((o, i) => (
                                        <div>
                                            <Link to={'/rowjobs/ongoing/' + o.warden.id}>{o.warden.firstname + " " + o.warden.surname}<Badge color="primary" style={{ float: "right" }}>{o.count}</Badge></Link>
                                        </div>
                                    ))
                                }
                                <p>

                                </p>
                            </CardBody>
                        </Card>
                    ) : ''}
                    {this.state.user && (this.state.user.role.includes("Administrator") || this.state.user.role.includes("Warden")) ? (
                        <Card>
                            <CardHeader><CardTitle tag="h4"><FaTools /> Bridge Inspections Due</CardTitle></CardHeader>
                            <CardBody>

                                {
                                    this.state.duebywarden.map((o, i) => (
                                        <div>
                                            <Link to={'/bridges/due/' + o.warden.id}>{o.warden.firstname + " " + o.warden.surname}<Badge color="primary" style={{ float: "right" }}>{o.count}</Badge></Link>
                                        </div>
                                    ))
                                }
                                <p>

                                </p>
                            </CardBody>
                        </Card>
                    ) : ''}


                </CardDeck>
            </div>
        );
    }
}
export const Home = withTranslation('en')(HomePlain);