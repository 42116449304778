import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import AuthorizeRoute from '../api-authorization/AuthorizeRoute';
import { RowPersonList } from './RowPersonList';


export function RowPersonRouter() {
	let match = useRouteMatch();

	return (
		<Switch>
			{/*<AuthorizeRoute path={`${match.path}/calendar`} component={RowMaterialCalendar} />*/}
			{/*<AuthorizeRoute path={`${match.path}/add`} component={RowMaterialAdd} />*/}
			{/*<Route path={`${match.path}/delete/:id`} component={RowMaterialDelete} />*/}
			
			{/*<AuthorizeRoute path={`${match.path}/edit/:id`} component={RowPersonEdit} />*/}
			{/*<AuthorizeRoute path={`${match.path}/edit`} component={RowMaterialEdit} />*/}

			<AuthorizeRoute path={`${match.path}`} component={RowPersonList} />
		</Switch>
	);
}