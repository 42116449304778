import React, { Component } from 'react';
import {
    Card, CardBody, CardText, CardHeader, CardFooter, CardTitle, CustomInput,
    Col, Row, FormGroup, Form, Label, Input, Button, Badge, InputGroup, FormText,
    Table

} from 'reactstrap';
import { FaTrash } from 'react-icons/fa';
import rowMaterialService from '../rowmaterials/RowMaterialService';
import { AsyncPaginate, withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from "react-select/creatable";
import rowGrantService from '../rowgrants/RowGrantService';
import RowAddMaterialPopup from '../rowmaterials/RowAddMaterialPopup';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

class MaterialsEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            materials: props.value,
            grant: props.grant
        }
    }
    handleChange = (o, i) => {
        this.setState({ materials: [...this.state.materials.slice(0, i), o, ...this.state.materials.slice(i + 1)] });
        if (this.props.onChange) {
            this.props.onChange(this.state.materials);
        }
    }

    handleGrantSelectChange(e) {
        this.setState({ grant: e.obj });
        if (this.props.onGrantChange) {
            this.props.onGrantChange(e.obj);
        }
    }
    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }
    handleAddMaterial = () => {
        let newmaterials = [...this.state.materials, { id: this.uuidv4(), quantity: 0, cost: 0.00, material: null, index: this.state.materials.length }];
        this.setState({ materials: newmaterials });
        if (this.props.onChange) {
            this.props.onChange(this.state.materials);
        }
    }
    handleDeleteMaterial = (k) => {
        this.setState({ materials: [...this.state.materials.slice(0, k), ...this.state.materials.slice(k + 1)] });
    }
    handleAddNewMaterial = (obj) => {
        let newmaterials = [...this.state.materials, { id: this.uuidv4(), quantity: 0, cost: obj.cost, material: obj, index: this.state.materials.length }];
        this.setState({ materials: newmaterials });
        if (this.props.onChange) {
            this.props.onChange(this.state.materials);
        }
    }
    totalMaterialCost() {
        let tot = 0.0;
        for (var mat of this.state.materials) {
            tot = tot + (mat.cost * mat.quantity)
        }
        return parseFloat(tot).toFixed(2);
    }
    grantFundedMaterialCost() {
        let tot = 0.0;
        for (var mat of this.state.materials) {
            tot = tot + (mat.grantFunded ? (mat.cost * mat.quantity) : 0.0);
        }
        return parseFloat(tot).toFixed(2);
    }
    bbnpaMaterialCost() {
        let tot = 0.0;
        for (var mat of this.state.materials) {
            tot = tot + (mat.grantFunded ? 0.0 : (mat.cost * mat.quantity));
        }
        return parseFloat(tot).toFixed(2);
    }
    render() {
        return (
            <Card>
                <CardHeader>Materials Used</CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label for="grant" sm={2}>Grant Funding (GF)</Label>
                        <Col sm={10}>
                            <AsyncPaginate
                                value={this.state.grant ? { label: this.state.grant.name, value: this.state.grant.id } : {}}
                                loadOptions={
                                    async (search, loadedGrantOptions, { page }) => {
                                        const responseJSON = await rowGrantService.selectlist(search, page, 0);
                                        return {
                                            options: responseJSON.result,
                                            hasMore: responseJSON.hasmore,
                                            additional: {
                                                page: page + 1,
                                            },
                                        };
                                    }}
                                onChange={this.handleGrantSelectChange.bind(this)}
                                additional={
                                    { page: 1, }
                                }
                                readOnly={this.state.locked} />
                        </Col>
                    </FormGroup>
                    <Table responsive>
                        <thead>
                            <tr>
                                <td>Material</td>
                                <td>Quantity</td>
                                <td>Unit Cost</td>
                                <td>GF</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.materials.map((material, index) => (
                                    <MaterialsLineEditor key={material.id} index={index} value={material} onDelete={this.handleDeleteMaterial} onChange={this.handleChange} readOnly={this.props.readOnly} />
                                ))
                            }
                        </tbody>
                    </Table>
                    <div style={{ float: "right" }}>Total material cost = &pound;{this.totalMaterialCost()}</div><br />
                    <div style={{ float: "right" }}>Grant material cost = &pound;{this.grantFundedMaterialCost()}</div><br />
                    <div style={{ float: "right" }}>BBNPA material cost = &pound;{this.bbnpaMaterialCost()}</div>
                </CardBody>
                <CardFooter>
                    <Button onClick={this.handleAddMaterial}>Add</Button>&nbsp;
                    <RowAddMaterialPopup onAdd={this.handleAddNewMaterial} />
                </CardFooter>
            </Card>
        )
    }
}
class MaterialsLineEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            material: props.value
        }
    }
    handleChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state.material, this.props.index);
        }
    }
    handleDeleteClick = () => {
        if (this.props.onDelete) {
            this.props.onDelete(this.props.index);
        }
    }
    handleMaterialSelectChange(e) {
        if (e.obj) {
            this.state.material["material"] = e.obj;
            this.state.material["cost"] = e.obj.cost;
            this.setState({ material: this.state.material });
        }
        else {
            this.state.material["material"] = { id: null, name: e.label, quantity: 0.00, cost: 0.00 }
            this.setState({ material: this.state.material });
        }
        this.handleChange();
    }
    handleInputChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.type === 'radio' && target.name === 'externallyReported' ? target.value == 'true' : target.value;
        const name = target.name;

        this.state.material[name] = value;
        this.setState({ material: this.state.material });
        this.handleChange();
    }
    handleGrantFundedChange = (e) => {
        this.state.material.grantFunded = e.target.checked;
        this.setState({ material: this.state.material });
        this.handleChange();
    }
    render() {
        return (
            <tr>
                <td style={{ minWidth: "200px" }}>
                    <CreatableAsyncPaginate
                        value={this.state.material.material ? { label: this.state.material.material.name, value: this.state.material.material.id } : {}}
                        loadOptions={
                            async (search, loadedRowMaterialOptions, { page }) => {
                                const responseJSON = await rowMaterialService.selectlist(search, page, 0);
                                return {
                                    options: responseJSON.result,
                                    hasMore: responseJSON.hasmore,
                                    additional: {
                                        page: page + 1,
                                    },
                                };
                            }}
                        onChange={this.handleMaterialSelectChange.bind(this)}
                        //onCreateOption={onCreateOption}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        additional={
                            { page: 1, }
                        }
                        readOnly={this.props.readOnly}
                    />
                </td>
                <td style={{ minWidth: "100px", maxWidth: "150px" }}><Input type="number" name="quantity" value={this.state.material.quantity} onChange={this.handleInputChange} readOnly={this.props.readOnly}/></td>
                <td style={{ minWidth: "100px", maxWidth: "150px" }}><Input type="number" name="cost" onChange={this.handleInputChange} value={this.state.material.cost} readOnly={this.props.readOnly} /></td>
                <td><CustomInput checked={this.state.material.grantFunded} type="switch" id={'grantFunded' + this.state.material.id} name="grantFunded" label="" onChange={this.handleGrantFundedChange} /></td>
                <td><Button onClick={this.handleDeleteClick}><FaTrash /></Button></td>
            </tr>
        )
    }
}

export default MaterialsEditor;