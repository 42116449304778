import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import rowJobService from './RowJobService';
import rowStatusService from '../rowstatuses/RowStatusService';
import { Pager } from '../Pager';
import { FormGroup, Form, Input, Button } from 'reactstrap';
import { FaKey, FaSortAlphaDown, FaSortAlphaUp, FaEdit, FaTrash } from 'react-icons/fa';
import FilterManager from '../filtermanager/FilterManager';
import JobMap from '../pathmap/JobMap';
import RowJobCsvPopup from './RowJobCsvPopup';

export class RowJobList extends Component {
    constructor(props) {
        super(props);
        const { match } = this.props;
        this.ongoingwardenid = match.params.ongoingwardenid;
        let filteroverride = null;
        if (this.ongoingwardenid) {
            let ongoing = null;
            (async () => {
                try {
                    const data = await rowStatusService.ongoing();
                    ongoing = data.rowstatus;
                    filteroverride = [
                        {
                            condition: "lt",
                            id: "9a250652-1381-4ab4-b19b-6b5f40a602bd",
                            name: "status",
                            value: ongoing.id
                        },
                        {
                            condition: "eq",
                            id: "141132c0-0d6a-4f90-aa71-dbad4db614e3",
                            name: "assignedto",
                            value: this.ongoingwardenid
                        }
                    ];
                    this.setState({ filteroverride: filteroverride });
                } catch (ex) { }
            })()
        }
        this.state = {
            entities: [],
            page: 1,
            pageSize: 20,
            maxPages: 15,
            sortOrder: "duedate_desc",
            searchString: "",
            loading: true,
            filters: filteroverride ? filteroverride : [],
            filteroverride: filteroverride,
            showmap: false
        };
        
    }

    componentDidMount() {
        this.populateData();
    }

    handlePageChange = (page) => {
        this.setState({ page: page }, () => this.populateData());
    }

    handleHeaderClick = (event, header) => {
        event.preventDefault();

        let newSortOrder = this.state.sortOrder;

        switch (header) {
            case 'jobnumber': {
                newSortOrder = this.state.sortOrder === 'jobnumber' ? 'jobnumber_desc' : 'jobnumber';
                break;
            }
            case 'prow': {
                newSortOrder = this.state.sortOrder === 'prow' ? 'prow_desc' : 'prow';
                break;
            }
            case 'community': {
                newSortOrder = this.state.sortOrder === 'community' ? 'community_desc' : 'community';
                break;
            }
            case 'parish': {
                newSortOrder = this.state.sortOrder === 'parish' ? 'parish_desc' : 'parish';
                break;
            }
            case 'reporteddate': {
                newSortOrder = this.state.sortOrder === 'reporteddate' ? 'reporteddate_desc' : 'reporteddate';
                break;
            }
            case 'duedate': {
                newSortOrder = this.state.sortOrder === 'duedate' ? 'duedate_desc' : 'duedate';
                break;
            }
            case 'priority': {
                newSortOrder = this.state.sortOrder === 'priority' ? 'priority_desc' : 'priority';
                break;
            }
        }

        this.setState({ page: 1, sortOrder: newSortOrder }, () => this.populateData());
        return false;
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    handleSearchFormSubmit = (event) => {
        event.preventDefault();
        this.setState({ page: 1 }, () => this.populateData());
        //this.populateData();
    }

    handleSearchFormReset = (event) => {
        event.preventDefault();
        this.setState({ page: 1, searchString: "" }, () => this.populateData());
    }
    selectJob = (entity) => {
        const { history } = this.props;
        history.push('/rowjobs/edit/' + entity.id);
        
    }
    renderTable() {
        const { entities, total, sortOrder } = this.state;

        return (
            <div>
                <JobMap
                    filters={this.state.filters}
                    visible={this.state.showmap}
                    sortOrder={this.state.sortOrder}
                    searchString={this.state.searchString}
                    onClose={this.mapclosed }
                />
                <table className='table table-striped' aria-labelledby="tableLabel">
                    <thead>
                        <tr>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'jobnumber')} >
                                    #&nbsp;
                                    {sortOrder == 'jobnumber' && <FaSortAlphaDown />}
                                    {sortOrder == 'jobnumber_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'prow')} >
                                    ROW&nbsp;
                                    {sortOrder == 'prow' && <FaSortAlphaDown />}
                                    {sortOrder == 'prow_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'parish')} >
                                    Parish&nbsp;
                                    {sortOrder == 'parish' && <FaSortAlphaDown />}
                                    {sortOrder == 'parish_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'community')} >
                                    Community&nbsp;
                                    {sortOrder == 'community' && <FaSortAlphaDown />}
                                    {sortOrder == 'community_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'reporteddate')} >
                                    Reported Date&nbsp;
                                    {sortOrder == 'reporteddate' && <FaSortAlphaDown />}
                                    {sortOrder == 'reporteddate_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'duedate')} >
                                    Due Date&nbsp;
                                    {sortOrder == 'duedate' && <FaSortAlphaDown />}
                                    {sortOrder == 'duedate_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th>
                                <a href="#" onClick={(e) => this.handleHeaderClick(e, 'priority')} >
                                    Priority&nbsp;
                                    {sortOrder == 'priority' && <FaSortAlphaDown />}
                                    {sortOrder == 'priority_desc' && <FaSortAlphaUp />}
                                </a>
                            </th>
                            <th />
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {entities.map(entity => {
                            return [(
                                <tr key={entity.id} style={{ cursor: 'pointer' }} onClick={() => this.selectJob(entity)}>
                                    <td>{entity.jobNumber}</td>
                                    <td>{entity.prow}</td>
                                    <td>{entity.parish ? entity.parish.name : ''}</td>
                                    <td>{entity.community ? entity.community.name.replace(" Community", "") : ''}</td>
                                    <td>{this.fixDate(entity.reportedDate)}</td>
                                    <td>{this.fixDate(entity.dueDate)}</td>
                                    <td>{entity.priorityLevel ? entity.priorityLevel.prowPriorityId : ''}</td>
                                    <td><Link to={'/rowjobs/delete/' + entity.id}><FaTrash /></Link></td>
                                    <td><Link to={'/rowjobs/edit/' + entity.id}><FaEdit /></Link></td>
                                </tr>
                                ), (entity.description !== "" ?
                                <tr>
                                    <td colspan="9">{entity.description}</td>
                                </tr>
                                : '')
                            ]
                        }
                            
                        )}
                    </tbody>
                </table>
                <Pager totalItems={total} page={this.state.page} pageSize={this.state.pageSize} maxPages={this.state.maxPages} handlePageChange={this.handlePageChange} />
            </div>
        );
    }
    fixDate(date) {
        return date != null && date.length > 0 ? (new Date(date.split('T')[0])).toLocaleDateString() : "";
    }
    showmap = () => {
        this.setState({ showmap: true });
    }
    mapclosed = () => {
        this.setState({ showmap: false });
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderTable();

        return (
            <div>
                <h1 id="tableLabel">Jobs</h1>
                <FilterManager onChange={this.filterChange} type={"rowjobfilters"} override={this.state.filteroverride }/>
                <div>&nbsp;</div>
                {/*<Link to='/rowjobs/edit/'>Add job</Link>*/}
                <Form inline onSubmit={this.handleSearchFormSubmit}>
                    <Button size="sm" href="/rowjobs/edit/">Add job</Button>&nbsp;
                    <FormGroup>
                        <Input type="text" name="searchString" value={this.state.searchString} onChange={this.handleInputChange} placeholder="search string" />
                    </FormGroup>&nbsp;
                    <Button size="sm">Search</Button>&nbsp;
                    <Button size="sm" onClick={this.handleSearchFormReset}>Reset</Button>&nbsp;
                    <Button size="sm" onClick={this.showmap}>Map</Button>&nbsp;
                    <RowJobCsvPopup
                        filters={this.state.filters}
                        sortOrder={this.state.sortOrder}
                        searchString={this.state.searchString}
                    />
                </Form><br />
                {contents}
            </div>
        );
    }
    filterChange = (filters) => {
        this.setState({ filters: filters });
        this.setState({ page: 1 }, () => this.populateData());
    }
    async populateData() {
        try {

            const data = await rowJobService.getData(
                this.state.page,
                this.state.pageSize,
                this.state.sortOrder,
                this.state.searchString,
                this.state.filters
            );
            data.entities.forEach(function (item) {
                var location = item.location;
                //item.location = location.coordinates;
                var leader = item.leader;
                if (leader) {
                    item.leader = leader.firstname + " " + leader.surname;
                    item.leaderid = leader.id;
                } else {
                    item.leader = "";
                }

            });
            this.setState({ total: data.total, entities: data.entities, loading: false });
        }
        catch (error) {
            // Handle error
        }
    }
}