import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants'

export class BridgeInspectionService  {
	async getxxData(page, pageSize, sortOrder, searchString) {
		const token = await authService.getAccessToken();
		const offset = (page - 1) * pageSize;

		// Taken from here: https://fetch.spec.whatwg.org/#fetch-api
		let url = "bridgeinspection?" + "offset=" + offset + "&limit=" + pageSize + "&sortOrder=" + sortOrder;
		if (searchString !== "")
			url += "&searchString=" + encodeURIComponent(searchString);

		try {
			const response = await fetch(url, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}

				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	async getData(page, pageSize, sortOrder, searchString, filters) {
		const token = await authService.getAccessToken();
		const offset = (page - 1) * pageSize;

		// Taken from here: https://fetch.spec.whatwg.org/#fetch-api
		let url = "bridgeinspection/list";
		//+ "offset=" + offset + "&limit=" + pageSize + "&sortOrder=" + sortOrder;
		//if (searchString !== "")
		//	url += "&searchString=" + encodeURIComponent(searchString);
		var json = {
			offset: offset,
			page: page,
			limit: pageSize,
			sortOrder: sortOrder,
			filters: filters,
			searchString: searchString
		}
		
		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('bridgeinspection/list', {
				method: 'POST',
				body: JSON.stringify(json),
				headers: headers,
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}

				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			throw error;
		}
	}

	async delete(entityId) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('bridgeinspection/' +entityId, {
				method: 'DELETE',
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async get(entityId) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('bridgeinspection/' + entityId, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	async add(entity) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('bridgeinspection', {
				method: 'POST',
				body: JSON.stringify(entity),
				headers: headers,
			});

			if (response.ok) {
				//return;
				const jsonData = await response.json();
				return jsonData
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}

	}

	async update(id, entity) {
		const token = await authService.getAccessToken();

		try {
			const headers = { 'content-type': 'application/json' };
			if (token) headers['Authorization'] = `Bearer ${token}`;

			const response = await fetch('bridgeinspection/' + id, {
				method: 'PUT',
				body: JSON.stringify(entity),
				headers: headers,
			});

			if (response.ok) {
				return;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}


	async nearestobjects(northing, easting) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('bridgeinspection/nearestobjects?northing=' + northing + '&easting=' + easting , {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}
	async photolist(jobid) {
		const token = await authService.getAccessToken();

		try {
			const response = await fetch('bridgeinspection/photolist?jobid=' + jobid, {
				headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
			});

			if (response.ok) {
				const jsonData = await response.json();
				return jsonData;
			}
			else {
				if (response.status == 401) { // Refresh access token if it is expired
					window.location.href =
						`${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
				}
				throw new Error("HTTP error! Code: " + response.status);
			}
		}
		catch (error) {
			console.log(error);
			throw error;
		}
	}

	static get instance() { return bridgeInspectionService; } // TODO: Check singleton/lifetime
}

const bridgeInspectionService = new BridgeInspectionService();

export default bridgeInspectionService;